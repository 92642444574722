import React from "react";

export default function BasicsPopupFiber( { toggleCollapsed, data } ) {
  return(
    <div className="basics--popup-content">
    <h2 className="h2">{ data.title }</h2>
    { data.subtitle_1 && <h3 className="h3">{ data.subtitle_1 }</h3> }
    { data.text_1 && <p className="text--left">{ data.text_1 }</p> }
    <div className="basics--popup-cols basics--popup-cols-3">
        <div><p>{ data.col_1 }</p></div>
        <div><p>{ data.col_2 }</p></div>
        <div><p>{ data.col_3 }</p></div>
    </div>
    { data.subtitle_2 && <h3 className="h3">{ data.subtitle_2 }</h3> }
    { data.text_2 && <p className="text--left">{ data.text_2 }</p> }
    { data.extra_1 && <p className="basics--popup-extra">
      { data.extra_1 }
    </p> }
    { data.subtitle_3 && <h3 className="h3">{ data.subtitle_3 }</h3> }
    { data.text_3 && <p className="text--left">{ data.text_3}</p> }
    <div onClick={ toggleCollapsed } className="btn btn--green">{ data.less }</div>
  </div>
  )
} 