import {React, useState } from "react";
import VideoCard from "./VideoCard";
import Author from './Author.js'

export default function Video( { data } ) {

  const [trigger, setTrigger] = useState(0);

  function stopVideos(x) {
    // setTrigger((trigger) => trigger + 1);
    setTrigger( (trigger) => x )
  }

  const videoCards = data.cards.map( item => {
    return <VideoCard trigger={trigger} clickEvent={ stopVideos } key={ item.title } data={item} />
  } )

  return(
    <section className="video">
      <div className="container">
        {/* <Author data={ data.author } /> */}
        <ul>
          { videoCards }
        </ul>

        <a target="_blank" rel="noreferrer" href={ data.url } className="btn btn--yellow">{ data.button }</a>
      </div>
    </section>
  )
}