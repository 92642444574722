import React from "react";
import BasicsPopupProtein from './BasicsPopupProtein';
import BasicsPopupFat from './BasicsPopupFat';
import BasicsPopupSugar from './BasicsPopupSugar';
import BasicsPopupFiber from './BasicsPopupFiber';

export default function BasicsPopup( { toggleCollapsed, data } ) {
  let type =  data.popupType; 
  function stopPropagation(e) {
    e.stopPropagation();
  }
  return(
    <div onClick={ toggleCollapsed } className="basics--popup">
      <div onClick={ stopPropagation } className="basics--popup-inner">
        <div onClick={ toggleCollapsed } className="basics--popup-close">&#10005;</div>
          { type === "protein" && <BasicsPopupProtein toggleCollapsed={ toggleCollapsed } data={ data } /> }
          { type === "fat" && <BasicsPopupFat toggleCollapsed={ toggleCollapsed } data={ data } /> }
          { type === "fiber" && <BasicsPopupFiber toggleCollapsed={ toggleCollapsed } data={ data } /> }
          { type === "sugar" && <BasicsPopupSugar toggleCollapsed={ toggleCollapsed } data={ data } /> }
        </div>
    </div>
  )
} 