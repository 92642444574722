import React from "react";

export default function Intro( {data} ) {
  return(
    <section className="intro">
      <div className="container">
        <img className="intro--logo" src={ data.image } alt="" />
        <h1 className="h1">{ data.title }</h1>
        <h2 className="h2">{ data.subtitle }</h2>
        <p>{ data.text_up }</p>
        </div>
    </section>
  )
}