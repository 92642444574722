import React from "react";
import CardsCard from "./CardsCard";

export default function Cards( {data} ) {
  
  const cardsCards = data.cards.map( item => {
    return <CardsCard key={ item.title } data={ item } />
  })

  return(
    <section className="cards">
      <div className="container">
          <h2 className="h1">{ data.title }</h2>
          <ul>
            { cardsCards }
         </ul>
      </div>
    </section>
  )
}