import React from "react";
import SocialIcon from "./SocialIcon";

export default function Footer( {data}) {
  
  const socialIcons = data.social.map( item => {
    return <SocialIcon key={item.icon} data={item} />
  })
  
  return(
    <section className="footer">
      <div className="container">
        <h2 className="h2">{ data.title }</h2>
        <p dangerouslySetInnerHTML={{ __html:data.text }}></p>
        <ul>
          { socialIcons }
        </ul>
      </div>
    </section>
  )
}