import React from "react";

export default function CardsCard( {data} ) {
  return(
    <li>
      <article>
          <img src={ data.image } alt={ data.title } />
          <h3 className="h3">{ data.title }</h3>
          <p dangerouslySetInnerHTML={{ __html:data.text }}></p>
      </article>
    </li>
  )
}