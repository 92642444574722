import React from "react";

export default function BasicsPopupSugar( { toggleCollapsed, data } ) {
  return(
        <div className="basics--popup-content">
          <h2 className="h2">{ data.title }</h2>
          { data.subtitle_1 && <h3 className="h3">{ data.subtitle_1 }</h3> }
          { data.text_1 && <p className="text--left">{ data.text_1 }</p> }
          { data.extra_1 && <p className="basics--popup-extra">
            { data.extra_1 }
          </p> }
          { data.subtitle_2 && <h3 className="h3">{ data.subtitle_2 }</h3> }
          { data.text_2 && <p className="text--left">{ data.text_2 }</p> }
          { data.subtitle_img && <h3 className="h3">{ data.subtitle_img }</h3> }
          { data.text_img && <p className="text--left">{ data.text_img}</p> }
          <div className="basics--popup-cols">
              { data.col_1 && <div>
                { data.image_3 && <img width="50" src={ data.image_3 } alt="alt 3" /> }
                <span>{data.col_1}</span>
                <ul dangerouslySetInnerHTML={{ __html:data.col_1_2 }}></ul>
              </div> }
              
              <div className="basics--popup-flex" >
                  { data.col_2 && 
                  <span className="basics--popup-flex">
                    { data.image_2 && <img width="50" src={ data.image_2 } alt="alt 2" /> }
                    { data.col_2 }
                  </span> }

                  { data.col_2_2 && 
                <span className="basics--popup-flex">
                  { data.image_2 && <img width="50" src={ data.image_1 } alt="alt 2" /> }
                  { data.col_2_2 }
                </span> }
              </div>
              
          </div>
          { data.subtitle_3 && <h3 className="h3">{ data.subtitle_3 }</h3> }
          { data.text_3 && <p className="text--left">{ data.text_3}</p> }
          <div onClick={ toggleCollapsed } className="btn btn--green">{ data.less }</div>
        </div>
  )
} 