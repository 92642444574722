import React from "react";

export default function BasicsPopupProtein(  { toggleCollapsed, data } ) {
  return(
        <div className="basics--popup-content">
          <h2 className="h2">{ data.title }</h2>
          { data.subtitle_1 && <h3 className="h3">{ data.subtitle_1 }</h3> }
          { data.text_1 && <p className="text--left">{ data.text_1 }</p> }
          { data.subtitle_2 && <h3 className="h3">{ data.subtitle_2 }</h3> }
          { data.text_2 && <p className="text--left">{ data.text_2 }</p> }
          { data.extra_1 && <p className="basics--popup-extra">
            { data.extra_1 }
          </p> }
          { data.subtitle_3 && <h3 className="h3">{ data.subtitle_3 }</h3> }
          { data.text_3 && <p className="text--left">{ data.text_3 }</p> }
          { data.subtitle_img && <h3 className="h3">{ data.subtitle_img }</h3> }
          <div className="basics--popup-cols">
            <div>
              { data.image_1 && <img src={ data.image_1 } alt="alt 1" /> }
              { data.col_1 && <p>{ data.col_1 }</p> }
            </div>
            <div>
              { data.image_2 && <img src={ data.image_2 } alt="alt 2" /> }
              { data.col_2 && <p>{ data.col_2 }</p> }
            </div>
          </div>
          { data.extra_2 && <p className="basics--popup-extra">
            { data.extra_2 }
          </p> }
          <div onClick={ toggleCollapsed } className="btn btn--green">{ data.less }</div>
        </div>
  )
} 