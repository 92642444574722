const data = {
  banner: {
    title: "ROZOHRAJTE TANIER FARBAMI A&nbsp;<span>HRAJTE O SKVELÉ&nbsp;VÝHRY!</span>",
    text: "Súťaž prebieha od 1. 2. do 15. 3. 2023<br />v ktorejkoľvek predajni na území Slovenska.",
    image: "images/layout/banner-1-sk.png",
    image_2: "images/layout/banner-2-sk.png",
    button: "Chcem súťažiť!",
    url: "https://www.bonduelle-sutaz.sk/"
  },
  intro: {
    title: "Rozohrajte tanier farbami",
    subtitle: "Objavte s Bonduelle recept na pestrý život",
    text: "Pozrite sa pod pokrievku zdravého stravovania. Na našom virtuálnom tanieri pre vás spoločne s inžinierkou výživy a kvality potravín Karolínou Fourovou servírujeme šťavnaté informácie o všetkom, čo by nemalo v správne vyváženom jedálničku chýbať. Tak s chuťou do toho!",
    text_up: "Pozrite sa pod pokrievku zdravého stravovania. Na našom virtuálnom tanieri pre vás servírujeme šťavnaté informácie o všetkom, čo by nemalo v správne vyváženom jedálničku chýbať. Tak s chuťou do toho!",
    image: "images/layout/logo.png",
  },
  video: {
    button: "Ešte viac receptov a inšpirácie",
    url: "https://www.bonduelle.sk/recepty-domov",
    cards: [
      {
        title: "Farebné a vyvážené jedlo aj na cesty",
        coverImage: "images/layout/video-1.jpg",
        videoUrl: "video-1.mp4"
      },
      {
        title: "Ako jesť viac strukovín",
        coverImage: "images/layout/video-2.jpg",
        videoUrl: "video-2.mp4"
      },
      {
        title: "Ako dostať do jedálnička viac vlákniny",
        coverImage: "images/layout/video-3.jpg",
        videoUrl: "video-3.mp4"
      },
      {
        title: "Varenie<br />bez plytvania",
        coverImage: "images/layout/video-4.jpg",
        videoUrl: "video-4.mp4"
      }
    ],
    author: {
      title: "Karolína Fourová radí",
      text: "Inžinierka v oblasti Kvality potravín a výživy<br />a dvojnásobná víťazka súťaže Foodblog roku 2021 a 2022.",
      image: "images/layout/author.jpg"
    }
  },
  basics: {
    title: "Bez základov to nejde",
    cards: [
      {
        title: "Bielkoviny",
        text: "Bez bielkovín by neexistoval pohyb. Sú základným stavebným materiálom svalov, ...",
        button: "Viac informácií",
        url: "#",
        popup: {
          title: "Bílkoviny",
          popupType: "protein",
          subtitle_1: "Bielkoviny niesú len potravou pre svaly",
          text_1: "Bez bielkovín by neexistoval pohyb. Sú základným stavebným materiálom svalov, nájdete ich v kostiach aj šľachách. Medzi bielkoviny však patria aj hormóny, ktoré ľudské telo potrebuje na trávenie všetkej potravy, a taktiež hormón inzulín, ktorý pomáha udržiavať zdravú hladinu cukru v krvi.",
          subtitle_2: "Koľko bielkovín by sme mali jesť?",
          text_2: "Oficiálne odporúčanie na množstvo bielkovín (alebo tiež proteínov) v jedálnom lístku je 0,8–1 g na 1 kg hmotnosti človeka. Nadmerné porcie bielkovín, ktoré môžeme občas vidieť u športovcov, telo nezvládne na budovanie svalov využiť. Plnohodnotné bielkoviny sú dôležité aj vo výžive detí.",
          extra_1:"Nevyužité bielkoviny ľudské telo vylúči. Nie je to však len tak. Ich nadbytok musia spracovať obličky, ktoré tak dostanú poriadne zabrať.",
          subtitle_img: "Ako vieme využívať bielkoviny",
          image_1: "images/layout/protein-1.png",
          image_2: "images/layout/protein-2.png",
          subtitle_3: "Kde bielkoviny nájdeme",
          col_1: "Z bielkovín živočíšneho pôvodu využijeme cca 70 %",
          col_2: "Z bielkovín rastlinného pôvodu využijeme cca 40 %",
          text_3: "Bielkoviny sú zložené z esenciálnych aminokyselín. Ľudský organizmus ich však sám vyrobiť nedokáže, a preto ich musí prijímať z jedla. Žiadnu živinu však nedokážeme vstrebať bez zvyšku. Niektoré bielkoviny vie ľudské telo využiť lepšie než iné.",
          extra_2: "Najkvalitnejšou bielkovinou vôbec je mliečna bielkovina (kazeín) a bielkovina vaječného bielku. Medzi najlepšie vstrebateľné rastlinné bielkoviny patria sójová, hrachová a pšeničná.",
          less: "Späť"
        }
      },
      {
        title: "Sacharidy",
        text: "Energia pre telo aj mozog. Tak by sa dali v skratke opísať sacharidy. Sú nutným palivom ...",
        button: "Viac informácií",
        url: "#",
        popup: {
          popupType: "sugar",
          title: "Sacharidy",
          subtitle_1: "Sacharidy poháňajú telo",
          subtitle_2: "Koľko sacharidov by sme mali jesť?",
          text_2: "Aký veľký podiel by mali v jedálnom lístku zaujímať sacharidy? Zhruba 55–60 % všetkej prijatej energie. Najväčšie zastúpenie by mali mať sacharidy komplexné. Tie ľudský organizmus trávi pomaly a energiu z nich získava postupne. Vyvarovať by sme sa, naopak, mali nadmernej konzumácie jednoduchých a pridaných cukrov, ktoré nájdeme nielen v sladkostiach, ale aj v ochutených mliečnych výrobkoch alebo sladených limonádach.",
          extra_1:"Nie je sacharid ako sacharid. Jedny sú prospešné a v jedálnom lístku by mali mať jasnú prevahu, iné sú skôr pre potešenie duše.",
          subtitle_img: "Kde ich nájdeme",
          text_img: "Bohatým zdrojom „tých správnych“ komplexných sacharidov sú:",
          col_1: "Obilniny a výrobky z nich",
          col_1_2: "<li>Múka</li><li>Pečivo</li><li>Cestoviny</li><li>Raňajkové cereálie a kaše</li>",
          image_1: "images/layout/carbs-2.png",
          col_2: "Strukoviny",
          image_2: "images/layout/carbs-1.png",
          col_2_2: "Zemiaky",
          image_3: "images/layout/carbs-3.png",
          subtitle_3: "Nie všetky jednoduché cukry sú zlé",
          text_3: "Jednoduchý cukor je aj fruktóza (prirodzene sa vyskytujúci ovocný cukor) alebo laktóza (prirodzene sa vyskytujúci mliečny cukor). Ovociu alebo neochuteným mliečnym výrobkom sa však kvôli tomu nevyhýbajte. Cukry v týchto potravinách sprevádza množstvo prospešných živín, dôležitých minerálnych látok a vitamínov.",
          less: "Späť"
        }
      },
      {
        title: "Tuky",
        text: "Tuky sú jednou z troch základných živín. Ľudské telo ich potrebuje nielen ako zdroj energie, ale aj ...",
        button: "Viac informácií",
        url: "#",
        popup: {
          title: "Tuky má naše telo rado",
          popupType: "fat",
          text_1: "Tuky sú jednou z troch základných živín. Ľudské telo ich potrebuje nielen ako zdroj energie, ale aj na stavbu buniek a pre správnu funkciu srdca a ciev. Dôležitú rolu hrajú aj pri výrobe hormónov a vstrebávaní niektorých vitamínov.",
          subtitle_2: "Koľko tukov by sme mali jesť?",
          text_2: "Tuky by v jedálnom lístku mali tvoriť asi 20–35 % z celkovej prijatej energie. Konkrétne množstvo však závisí od vašej telesnej konštitúcie, pohlavia, množstva pohybu, ale aj od toho, či je vaším cieľom pribrať, alebo naopak, pár kíl schudnúť.",
          subtitle_3: "Nie je tuk ako tuk",
          text_3: "Dve veci majú všetky tuky spoločné – majú vysoký obsah kalórií a sú zložené z mastných kyselín. Sú však tuky vhodnejšie a tuky menej vhodné. Vhodnejšie nenasýtené tuky pomáhajú okrem iného znižovať hladinu cholesterolu a riziko srdcových ochorení. Menej vhodné nasýtené tuky robia presný opak.",
          subtitle_4: "Ideálna skladba tukov v jedálnom lístku",
          text_4: "Najmenej 2/3 nenasýtené mastné kyseliny (rastlinné tuky a ryby)<br />Maximálne 1/3 nasýtené mastné kyseliny (živočíšne tuky a tropické oleje)",
          subtitle_img: "Zdroje tukov okolo nás",
          image_1: "images/layout/fat-1.svg",
          col_1: "<span>Nenasýtené mastné kyseliny:</span><span>rastlinné oleje, orechy, semienka a rybí tuk</span>",
          image_2: "images/layout/fat-2.svg",
          col_2: "<span>Nasýtené mastné kyseliny:</span><span>tučné mäso, maslo, masť, tučné mliečne výrobky a tropické oleje (kokosový, palmový)</span>",
          less: "Späť"
        }
      },
      {
        title: "Vláknina",
        text: "O vláknine sa príliš často nehovorí, v jedálnom lístku pritom hrá jednu z hlavných rolí...",
        button: "Viac informácií",
        url: "#",
        popup: {
          title: "Vláknina",
          popupType: "fiber",
          subtitle_1: "Zázrak menom vláknina",
          text_1: "O vláknine sa príliš často nehovorí, v jedálnom lístku pritom hrá jednu z hlavných rolí. Pomáha s trávením, keďže vyživuje priateľské črevné baktérie a ako „kefa“ čistí črevo, znižuje hladinu cholesterolu a prispieva k udržaniu normálnej hladiny cukru v krvi. Vláknina zároveň predlžuje pocit sýtosti.",
          subtitle_2: "Koľko vlákniny by sme mali jesť?",
          text_2: "S vlákninou je to jednoduchá matematika. Dospelý človek by jej mal zjesť približne 30 gramov denne. U detí zistíte orientačnú potrebu vlákniny jednoduchým výpočtom. Stačí, keď k veku dieťaťa pripočítate číslovku 5.",
          extra_1:"Nadbytok vlákniny v jedálnom lístku je rovnako nežiaduci ako jej nedostatok. Keď je jej príliš, dochádza k horšiemu vstrebávaniu niektorých živín, ktoré slúžia ako zdroj energie (bielkoviny a esenciálne aminokyseliny), ale taktiež vápnika alebo zinku.",
          subtitle_img: "Vlákninu delíme do troch kategórií",
          col_1: "Rozpustná",
          col_2: "Polorozpustná",
          col_3: "Nerozpustná",
          subtitle_3: "Kde vlákninu hľadať?",
          text_3: "Bohatými zdrojmi nerozpustnej vlákniny sú celozrnná múka a výrobky z nej, obilné vločky, strukoviny, ovocie a zelenina, semienka aj orechy. V ovocí a zelenine, naopak, nájdeme viac vlákniny rozpustnej. Vláknina polorozpustná stojí niekde uprostred a jej zdrojom sú napríklad ovsené vločky alebo niektoré ovocie. Neexistuje vláknina lepšia a horšia. Všetky vyššie spomenuté druhy potrebujeme a v jedálnom lístku by mali mať svoje miesto.",
          less: "Späť"
        }
      }
    ]
  },
  cards: {
    title: "Čo neviete o mrazení a konzervovaní",
    cards: [
      {
        image:"images/layout/card-1.png",
       title: "Ako od farmára",
       text: "<strong>Zelenina Bonduelle sa pestuje vo svojej prirodzenej sezóne a v oblastiach, ktoré sú pre danú plodinu najvhodnejšie.</strong> Aby bola zaručená najlepšia chuť, farba aj textúra a zelenina si zachovala maximum vitamínov a minerálnych látok, zbiera sa v plnej zrelosti a spracuje do 5 hodín od zberu." 
      },
      {
        image:"images/layout/card-2.png",
        title: "Mráz, ktorý šokuje",
        text: "V Bonduelle sa umytá a očistená zelenina <strong>blanšíruje na pare</strong> a následne šokovo zmrazí prúdom vzduchu s teplotou -30 °C. Zelenina sa zmrazí rýchlo za niekoľko minút. Vďaka tomu sa vnútri plodu pri mrazení nevytvoria veľké ľadové kryštály ako pri pomalom domácom mrazení pri vyššej teplote. <strong>Zelenina tak zostáva neporušená a uzamkne v sebe maximum vitamínov, chuti aj šťavnatosti.</strong>"
      },
       {
        image:"images/layout/card-3.png",
        title: "Každý piaty respondent si nesprávne myslí, že sú konzervy škodlivé.",
        text: "Najčastejšie obavy plynú z prítomnosti veľkého množstva tzv. „éčiek“ a konzervantov, ktoré však s konzervou nemajú nič spoločné. <strong>Slovo konzerva</strong> nenesie svoj názov preto, že by bola plná konzervantov, ale <strong>podľa procesu konzervácie, čo je spôsob uchovávania potravín.</strong><span>Dáta uvedené v texte vychádzajú z prieskumu realizovaného značkou Bonduelle a agentúrou STEM/MARK v roku 2021 v Česku.</span>" 
       },
       {
        image:"images/layout/card-4.png",
        title: "Tajomstvo konzervácie",
        text: "Ak nie sú v konzervách konzervanty, ako je teda možné, že je ich trvanlivosť taká dlhá? Tajomstvom je <strong>kombinácia dôkladného tepelného ošetrenia, dobre uzavretého balenia a jemne slaného nálevu, ktorý pôsobí ako prirodzený konzervant</strong> väčšiny druhov zeleniny a strukovín od Bonduelle. Vďaka tomu vydržia konzervy mesiace aj roky bez konzervačných látok." 
       },
       {
        image:"images/layout/card-5.png",
        title: "Vždy poruke",
        text: "<strong>Konzervovanú zeleninu a strukoviny využíva vo svojej kuchyni 94 % populácie,</strong> pričom ľudia si pochvaľujú predovšetkým jednoduchosť a rýchlosť prípravy. Podobne praktické sú aj mrazené produkty Bonduelle. <strong>Mrazenú zeleninu nezabudnite pred konzumáciou vždy tepelne spracovať.</strong> Návod na zadnej strane obalu vám poradí, ako správne postupovať." 
       }
    ]
  },
  footer: {
    title: "Bonduelle, už od roku 1853",
    text: "Bonduelle je rodinná spoločnosť, ktorá už sedem generácií pracuje v oblasti poľnohospodárskej produkcie. V rámci svojho hesla „Príroda je naša budúcnosť“, Bonduelle dbá na šetrný prístup k pôde a svojim pracovníkom a na efektívnu, inteligentnú a podpornú agroekológiu.",
    social: [
      {
        url: "https://www.facebook.com/bonduelle.cz",
        icon: "fb"
      },
      {
        url: "https://www.instagram.com/bonduelle.cz/",
        icon: "ig"
      },
      {
        url: "https://www.youtube.com/channel/UCkfv1NICOqgj2mJ4lMWp43Q",
        icon: "ytb"
      }
    ]
  },

  plate: {
    arrow: "Klikni tu",
    popups: [
      {
        img: "",
        id: 1,
        position: "",
        back: "Späť",
        next: "Ďalej",
        steps: [
          {
            title: "Zelenina je pestrá nielen na tvary a farby",
            text: "Každodenná konzumácia ovocia a zeleniny je pre ľudské telo nenahraditeľná. Vitamíny a ďalšie prospešné látky z nich získava v tej najlepšej možnej forme. Žiadny vitamínový doplnok stravy nenahradí pravidelnú konzumáciu zeleniny a ovocia z celého farebného spektra.",
            more: "Denne by sme mali zjesť približne 300-400 g zeleniny.",
          },
          {
            title: "Mrazená nie je horšia než čerstvá",
            text: "Zelenina, ktorá bola zozbieraná predčasne alebo čaká ovädnutá na pultoch obchodov, obsahuje vitamínov menej než zelenina čerstvá a v plnej sile. Iná sa však často v obchodoch nedá zohnať, predovšetkým v zime. Vďaka Bonduelle si môžete vychutnať zeleninu plnú chuti kedykoľvek potrebujete. Vďaka zberu v plnej zrelosti a moderným postupom mrazenia si zachováva vlastnosti čerstvej zeleniny.",
            more: "Zeleninu konzumujte po celý rok, bez ohľadu na ročné obdobie.",
          },
          {
            title: "Zeleninové tipy a triky",
            text: "Čerstvú zeleninu umývajte až tesne pred konzumáciou. Inak hrozí, že zmyjeme prirodzený ochranný film alebo potravinársky vosk, ktorý plody chráni. Neumytá zelenina vám v chladničke vydrží oveľa dlhšie.<br /><br />Mrazenú zeleninu rozmrazujte pod studenou tečúcou vodou a v pôvodnom balení. Po rozmrazení ju vždy tepelne spracujte. Stačí ju vložiť na 2–3 minúty do vriacej vody. Ak si neviete rady, pozrite sa na inštrukcie na zadnej strane obalu."
          },  
          {
            title: "Viete, ako správne skladovať zeleninu? ",
            text: "Priehradka na ovocie a zeleninu v chladničke je na skladovanie väčšiny druhov zeleniny ako stvorená. Vnútri nej je teplota 0–4 °C a zároveň vyššia vlhkosť, vďaka ktorej zelenina rýchlo nezvädne. V horných poschodiach chladničky, kde je teplota vyššia, uchovávajte napríklad zemiaky alebo papriky.",
            box: "<span>Zelenina, ktorá patrí do chladničky:</span><p>koreňová zelenina, karfiol, brokolica, šaláty.</p><span>Zelenina, ktorá do chladničky nepatrí:</span><p>paradajky, uhorky, baklažán, cukety, tekvica.</p>"
          },  
          {
            more: "Kvalitná mrazená zelenina je vhodnou alternatívou zeleniny čerstvej.",
            text_bottom: "Nie je žiadnym prekvapením, že najviac vitamínov, minerálnych látok a vlákniny obsahuje zelenina čerstvá, prípadne kvalitná zelenina mrazená. Akákoľvek tepelná úprava znamená menšiu či väčšiu stratu vitamínov. Najšetrnejšia je príprava zeleniny v pare, najviac vitamínov stráca pri dlhom varení vo vode.<br /><br />Prečo chutí kvalitná mrazená zelenina lepšie než zmrazená doma? V Bonduelle sa využíva šokové mrazenie prúdom vzduchu s teplotou -30 °C. Teplota v domácej mrazničke je oveľa vyššia a zmrazovanie tak trvá niekoľko hodín. Zelenina tak prichádza o časť vitamínov aj chuti a pri rozmrazení stratí viac šťavy."
          },
        ]
      },
      {
        img: "",
        id: 6,
        position: "",
        back: "Späť",
        next: "Ďalej",
        steps: [
          {
            title: "Bielkoviny nie sú len v mäse",
            text: "Obilniny a strukoviny nie sú iba zdrojom sacharidov a vlákniny. Obsahujú aj rastlinné bielkoviny. Tie síce nie sú tak ľahko vstrebateľné a využiteľné ako živočíšne, s trochou kreativity však zvládnete vykúzliť sýte a výživné jedlá aj bez mäsa, vajec a mlieka.",
            more: "Dobrý rastlinný „jogurt“ by mal obsahovať viac než 3 gramy bielkovín v 100 gramoch produktu.",
          },
          {
            title: "Stavte na strukoviny",
            text: "Rastlinné bielkoviny sa schovávajú napríklad v strukovinách. V bezmäsových receptoch je možné vďaka nim množstvo bielkovín ľahko zvýšiť.<ul><li>Vyskúšajte strukovinové cestoviny. </li><li>Cestovinový či zeleninový šalát doplňte konzervovaným cícerom. </li><li>Z bielej alebo červenej fazule vyčaríte skvelé nátierky. </li><li>Z červenej fazule upečte vláčne čokoládové brownies na desiatu. </li></ul>",
            more: "Zdrojom vápnika môže byť nečakane tofu. Na zrážanie sójovej bielkoviny sa v niektorých výrobkoch používajú vápenaté soli.",
          },
          {
            title: "Poriadne sýty obed",
            text: "Aby vás hlavné jedlo naozaj a na dlhý čas zasýtilo, malo by obsahovať približne 20 gramov bielkovín. Čo si však pod týmto množstvom predstaviť v bezmäsovom jedálnom lístku?<ul class=\npopup-list\n><li>80 gramov strukovín v suchom stave </li><li>250 gramov konzervovanej fazule alebo šošovice</li><li>150 gramov tofu</li><li>100 gramov seitanu</li></ul>",
          },
          {
            title: "Rastlinne aspoň raz do týždňa",
            text: "V jedálnom lístku každého z nás by sa mal vyskytovať aspoň jeden či dva bezmäsové dni týždenne. Nielenže sa tak staráte o našu planétu, ale váš jedálny lístok bude pestrejší, farebnejší a objavíte veľa nových a prekvapivých chutí.<span class=\"p-label\">Nie je jogurt ako jogurt</span>Nie každý rastlinný výrobok v tégliku, ktorý sa ako jogurt tvári, je naozaj vhodnou náhradou jogurtu. Ten správny musí mať v zložení probiotickú kultúru. Len taký výrobok podporuje trávenie a má pozitívny vplyv na imunitu. Ostatné výrobky sú skôr rastlinné dezerty. Často obsahujú veľké množstvo tuku aj pridaného cukru."
          },
          {
            title: "Pestrosť je základ ",
            text: "Poskladať plnohodnotný rastlinný jedálny lístok je síce ťažšie, ale ide to. Aby ste v jedálnom lístku mali bielkovín dostatok a zároveň s tým správnym zložením, je potrebné rôzne rastlinné zdroje vzájomne kombinovať a na tanieri ich striedať.",
            more: ""
          }
        ]
      },
      {
        img: "",
        id: 3,
        position: "",
        back: "Späť",
        next: "Ďalej",
        steps: [
          {
            title: "Tuky z ríše rastlín ",
            text: "Rastlinné oleje, orechy aj semená. V našom jedálničku by mali prevažovať rastlinné tuky nad živočíšnymi. Obsahujú prospešné nenasýtené mastné kyseliny a esenciálne mastné kyseliny omega-3 a omega-6, ktoré si telo nedokáže samo vyrobiť.<span class=\"p-label\">Prínosy omega-3 mastných kyselín</span><ul><li>Starajú sa o rast a kvalitu vlasov a kože aj správnu funkciu mozgu.</li><li>Napomáhajú znižovať krvný tlak a „zlý“ LDL cholesterol.</li><li>Regulujú hladinu tukov v krvi a znižujú pravdepodobnosť vzniku ochorení srdca a ciev.</li></ul>",    
          },
          {
            title: "Zdroje mastných kyselín",
            text_bottom: "<span class=\"p-label\">Nie všetko rastlinné je zdravé</span>Pochádzajú síce z rastlinnej ríše, ale zložením sa podobajú skôr živočíšnym tukom. Reč je o kokosovom a palmovom oleji. Kokosový olej obsahuje približne 90 % nasýtených mastných kyselín, palmový olej 50 %. Nasýtené tuky by sa na jedálnom lístku mali vyskytovať zriedka. Ak sú v nadbytku, prispievajú ku kardiovaskulárnym ochoreniam. Tropické oleje preto konzumujte výnimočne a v menšom množstve.",    
            box: "<span>Zdroje mastných kyselín</span><span><br />Omega-3:</span><p>tučné ryby, repkový olej, vlašské orechy a ľanový olej</p><span>Omega-6:</span><p>orechy, semienka, rastlinné oleje </p>"
          },
          {
            title: "Raňajkový hit – avokádo",
            text: "Avokádo sa stalo hitom raňajkových menu. Toto ovocie je bohaté na vlákninu a rastlinné tuky. Zdravý tuk je však stále tuk. Na jednu porciu stačí ¼ plodu.<span class=\"p-label\">Inteligentné kombinácie na tanieri</span>Nielen v prípade avokáda a olejov je dobré mať príjem tukov pod kontrolou. Ako na to a na čo si dať pozor? V obľúbenom šaláte s paradajkami a mozzarellou siahnite po syre „light“ a ušetrený tuk doplňte lyžicou olivového oleja. Orechového krému stačí do raňajkovej ovsenej kaše pridať len jednu kávovú lyžičku. Orechov na desiatu do jogurtu bude stačiť len pár kúskov.",    
          },
          {
            title: "Na panvicu či do šalátu? ",
            text: "Nie všetky tuky sú však rovnako zdravé v šaláte aj pri smažení na panvici. Panenské a za studena lisované oleje sa hodia v studenej kuchyni. Pri smažení a vysokých teplotách sa však rýchlo prepaľujú. Na tepelnú úpravu sú preto lepšie rafinované oleje, ktoré vydržia aj vyššie teploty.<br /><br />Najlepšie zloženie mastných kyselín aj vlastnosti pre tepelnú úpravu má obyčajný repkový olej. V studenej kuchyni ochutnajte okrem skvelého panenského olivového oleja aj menej známe za studena lisované, ako je makový, ľanový alebo panenský repkový.",    
          },
        ]
      },
      {
        img: "",
        id: 4,
        position: "",
        back: "Späť",
        next: "Ďalej",
        steps: [
          {
            title: "Šťavnaté a zdravé ovocie ",
            text: "Ovocie je plné vitamínov a minerálnych látok. To vie väčšina z nás. Málo sa však vie, že je zároveň dôležitým zdrojom tekutín. V jedálnom lístku pomáha zvýšiť množstvo rozpustnej aj nerozpustnej vlákniny. Zároveň obsahuje nenápadné, ale veľmi dôležité fytochemikálie (látky vyskytujúce sa v rastlinách), z ktorých niektoré môžu mať priaznivý účinok na ľudské zdravie.",
            more: "Denne by sme mali zjesť približne 100–200 g ovocia, čo zodpovedá napríklad strednému jablku, pomaranču alebo banánu.",
          },
          {
            title: "Na ovocie sčerstva ",
            text: "Najviac vitamínov a vlákniny získate z čerstvého ovocia, ktoré zjete „po starom“ vcelku. Obľúbené smoothie sú síce pohodlný spôsob, ako zaradiť ovocie do jedálneho lístka, ale z nutričného hľadiska také výhodné nie sú. Mixovaním sa potrebná vláknina rozseká na menšie časti a odšťavovaním o ňu prichádzate úplne. Ak navyše smoothie ihneď nevypijete, pôsobením svetla a kyslíka stráca vitamíny. Po niekoľkých hodinách potom vo fľaši ostane v podstate len voda a ovocný cukor.",
            box: "<p>Tip: Ideálna ovocná desiata Samotné&nbsp;ovocie nie je dostatočná desiata. V kombinácii s neochutením tvarohom, kefírom alebo jogurtom zasýti oveľa dlhšie.</p>"
          },
          {
            title: "Štyrikrát viac jednoduchého cukru ",
            text: "Ovocné šťavy a smoothie môžu byť problém aj v redukčnom jedálnom lístku. V jednom pohári sa schová aj niekoľko kusov ovocia, ktoré by ste naraz pravdepodobne nezjedli. Taký nenápadný pohár smoothie alebo džúsu potom môže oproti miske jogurtu s vločkami a ovocím obsahovať až štyrikrát viac jednoduchého cukru. <span class=\"p-label\">Ovocie do jedálnička patrí </span>Jedným z najčastejších mýtov spojených s chudnutím je, že ovocie pre cukor do redukčného jedálneho lístka nepatrí alebo by sa malo konzumovať len v určitom dennom čase. V rozumnom množstve ovocie patrí do každého jedálnička! Nie je totiž iba zdrojom jednoduchých cukrov. Organizmus z neho získava aj vlákninu, minerálne látky, vitamíny a ďalšie cenné živiny.",
          },
          {
            title: "Vo farbách dúhy ",
            text: "Každá farba ovocia obsahuje trochu inej prospešnej látky. Konzumáciou rôzne farebných druhov ovocia si zaistíte nielen prísun rozličných vitamínov, ale aj cenných rastlinných látok nazývaných flavonoidy.<br /><br />Fialové a tmavočervené plody obsahujú farbivá antokyány. Výskumy naznačujú, že majú viacero pozitívnych účinkov, ako je napríklad prevencia kardiovaskulárnych ochorení alebo rakoviny. Jablká sú zdrojom flavonoidu kvercetínu, ktorý pôsobí protizápalovo a výskumy naznačujú, že pomáha v prevencii kardiovaskulárnych a niektorých nádorových ochorení. Pomaranče a grapefruity sú zase zdrojom naringínu, ktorý pomáha zvyšovať pevnosť a elasticitu cievnych stien.",
          },
          {
            title: "Viete, ako správne skladovať ovocie?",
            more: "Plody, ktoré patria do chladničky: jablká, hrušky, slivky, broskyne, jahody, maliny, čučoriedky, pomaranče",
            more_bottom: "Plody, ktoré do chladničky nepatria: avokádo, banány, grapefruity, citróny"
          },
        ]
      },
      {
        img: "",
        id: 5,
        position: "",
        back: "Späť",
        next: "Ďalej",
        steps: [
          {
            title: "Zdravý zázrak menom strukoviny",
            text: "Strukoviny si miesto v jedálničku právom zaslúžia. Chutia skvele a sú bohaté na sacharidy, bielkoviny, vitamíny skupiny B a vlákninu. Práve tá v jedálnom lístku Slovákov chýba. Väčšina nezvláda zjesť ani polovicu odporúčanej dennej dávky vlákniny, teda 30 gramov.",
            box: "<p>Obsah základných živín, vlákniny aj vitamínov je vo všetkých druhoch strukovín približne rovnaký.</p>",
          },
          {
            title: "Strukoviny nemusia nadúvať",
            text: "Veľa ľudí si strukoviny spája s nadúvaním, preto ich radšej z jedálneho lístka úplne vyradzuje. Ochudobňovať sa o také výdatné a nutrične bohaté potraviny je však večná škoda. Zvlášť, keď sa dajú nestráviteľné oligosacharidy, ktoré túto nepríjemnosť spôsobujú, ľahko prekabátiť. <strong>Chcete vedieť ako?</strong>",
            more: "",
            box: "<span>Namočte ich</span><p>Strukoviny cez noc namočte do vody a tú potom zlejte. Na varenie použite vodu novú. Týmto spôsobom ľahko znížite obsah oligosacharidov takmer o polovicu.</p>"
          },
          {
            title: "Povolajte na pomoc mixér ",
            text: "Nepríjemným pocitom nadúvania je možné zabrániť aj mixovaním. Pri príprave strukovinových nátierok a dipov v mixéri oligosacharidy mechanicky rozpučíme. Stanú sa tak stráviteľnejšími.",
            more: "",
            box: "<span>Vyskúšajte klíčenie </span><p>Pri klíčení strukovín získava klíček energiu práve z nestráviteľných oligosacharidov. Ich obsah sa tak v naklíčenej strukovine zníži až o 80 %.</p>"
          },
          {
            title: "Strukoviny miesto mäsa",
            text: "Živočíšne a rastlinné bielkoviny na tanieri striedajte. Mäso môžete občas nahradiť strukovinami ako je šošovica, fazuľa alebo cícer.",
            more: "Strukoviny by sa mali v jedálnom lístku objavovať najmenej 2× týždenne. ",
            box: "<p>V 80 gramoch suchých alebo 250 gramoch varených či konzervovaných strukovín sa skrýva 20 gramov bielkovín. To je približné množstvo, ktoré by malo obsahovať jedno hlavné jedlo.</p>"
          },
          {
            title: "Bielkovinový match",
            text: "Nie všetky rastlinné bielkoviny majú rovnaké zloženie. Vo vyváženom rastlinnom jedálničku preto vzájomne kombinujte strukovinové bielkoviny napríklad so pšeničnými.",
            more: "",
            box: "<span>Chcete vedieť ako?</span><p>Šalát z bulguru či cestovín doplňte fazuľou alebo cícerom.</p><p>Nátierku z fazule natrite na pečivo.</p><p>Uvarte špagety s „bolonskou“ omáčkou z červenej šošovice.</p>"
          },
          {
            title: "Konzervované zdravie",
            text: "Ak chcete ušetriť čas, siahnite po strukovinách v konzerve. Obsah živín v konzervovaných strukovinách Bonduelle je rovnaký ako v tých, ktoré uvaríte doma. Vďaka kvalitnému a starostlivému procesu konzervácie si navyše zachovajú maximum chuti.",
            more: "",
            box: ""
          }
        ]
      },
      {
        img: "",
        id: 2,
        position: "",
        back: "Späť",
        next: "Ďalej",
        steps: [
          {
            title: "Obilniny ako základ jedálneho lístka",
            text: "Obilniny tvoria základňu potravinovej pyramídy. Sú totiž najvýznamnejším zdrojom komplexných sacharidov a vlákniny v ľudskej výžive. Poskytujú potrebnú energiu a podporujú činnosť tráviaceho traktu. Sú taktiež dôležitým zdrojom rastlinných bielkovín v jedálnom lístku, vitamínov B a E. ",
            box: "<span>Známe aj neznáme obilniny </span><p>Pšenica, žito, ovos, ryža, jačmeň</p><p>Pohanka, quinoa (mrlík čílsky), pšeno, cirok, amarant </p>",
          },
          {
            title: "Biela múka neškodí",
            text: "Navzdory svojej povesti nie je biela múka nezdravá. Je iba nutrične chudobnejšia, keďže sa melie z vylúpaných obiliek bez obalov a klíčka. Celozrnná múka sa melie aj s obalmi a klíčkom. Obsahuje preto viac vlákniny, dvojnásobok niektorých vitamínov, fosforu, vápnika aj rastlinného železa. A taktiež viac tuku.",
            more: "Ani prílohy by nemali byť jednotvárne. Každá z obilnín obsahuje iné množstvo vlákniny, vitamínov aj minerálnych látok, preto by sa na tanieri mali striedať.",
          },
          {
            title: "Lepok nelepí črevo, ale cesto",
            text: "V niektorých obilninách sa vyskytuje aj „celebrita“ v skupine rastlinných bielkovín – lepok. Ten svoje meno nezískal podľa toho, že by zalepoval črevo. Lepí cesto, robí ho pružné a ťažné. Len vďaka nemu si môžete pochutnávať napríklad na kysnutom pečive.<br /><span class=\"p-label\">Ako často a koľko čoho konzumovať</span>Pri výbere múky a pečiva dávajte prednosť celozrnným a žitným variantom, ktoré obsahujú viac vlákniny. Dobroty z bielej múky si doprajte iba občas na spestrenie jedálnička. ",
            more: "Osoby, ktoré netrpia alergiou či celiakiou, sa lepku báť nemusia ",
          }
        ]
      },
      {
        img: "",
        id: 7,
        position: "",
        back: "Späť",
        next: "Ďalej",
        steps: [
          {
            title: "Prekvapivá „superpotravina“",
            text: "Mlieko by sme mohli ľahko nazvať superpotravinou. Obsahuje niektoré z najkvalitnejších bielkovín vôbec a v podstate celé spektrum vitamínov (najmä vitamíny A, D, E, K). Pre ľudské telo navyše neexistuje lepší zdroj vápnika.<span class=\"p-label\">Ako často a koľko mlieka konzumovať?</span>Každý deň by ste mali zjesť dve až tri porcie mliečnych výrobkov. Čo si však pod raz porciou predstaviť? <span>Jeden väčší téglik jogurtu (200 gramov), </span><span>pohár mlieka, kefíru alebo iného kyslého mliečneho výrobku (250 gramov), </span><span>tri plátky tvrdého syra (50 gramov).</span>",
            more: "Biele mliečne výrobky sú základ. Ochutené jogurty a mliečne dezerty, ktoré obsahujú veľa pridaného cukru, konzumujte len občas.",
          },
          {
            title: "Mlieko nezahlieňuje",
            text: "Jedným z najrozšírenejších mýtov o mlieku je tvrdenie, že zahlieňuje. Žiadny výskum však nič také nepotvrdil. Mlieko iba vytvára na slizniciach úst a tráviaceho traktu ochranný film, ktorý sa o chvíľu rozpustí.",
            more: "Kyslé mliečne výrobky sú zdrojom živých priateľských baktérií. Podporujú trávenie a môžu prispieť aj k silnejšej imunite.",
          },
          {
            title: "Ako nahradiť mliečne výrobky",
            text: "Mlieko je v jedálnom lístku ťažko nahraditeľné. Pri alergii na mliečnu bielkovinu alebo v rastlinnom jedálničku však inú možnosť nemáte. Vyberajte potom také rastlinné nápoje a „jogurty“, ktoré sa „mliečnemu originálu“ čo najviac podobajú či už množstvom bielkovín, tukov, alebo sacharidov. Na obale výrobku hľadajte aj informáciu, že bol obohatený vápnikom a vitamínom D.",
            more: "Jogurty s obsahom tuku 2,5–3,5 % obsahujú viac bielkovín a vápnika než tučnejšie smotanové jogurty.",
          },
          {
            title: "Laktóza nie je prekážkou",
            text: "Intolerancia laktózy nie je dôvod, prečo mliečne výrobky z jedálnička vyradiť. Ochudobniť sa o všetky „mliečne“ pozitíva by bola obrovská škoda. Dnes už našťastie môžete vyberať z obrovskej ponuky bezlaktózových mliečnych výrobkov.<span class=\"p-label\">Mlieko do každého jedálnička</span>Nechutí vám samotný biely jogurt a premýšľate, ako do jedálnička „schovať“ viac mliečnych výrobkov? Môžete byť oveľa kreatívnejší. <span>Z tvarohu a syra cottage pripravte bylinkový dip. </span><span>Do raňajkovej ovsenej kaše vmiešajte biely jogurt alebo skyr. </span><span>Sladké palacinky alebo lievance podávajte s gréckym jogurtom a ovocím. </span><span>Obľúbenú vajíčkovú či rybaciu nátierku odľahčite tvarohom alebo žervé.</span>"
          }
        ]
      },
      {
        img: "",
        id: 8,
        position: "",
        back: "Späť",
        next: "Ďalej",
        steps: [
          {
            title: "Cenný zdroj kvalitných bielkovín",
            text: "Mäso má v jedálničku svoje miesto. Ľudské telo z neho získava plnohodnotné bielkoviny a esenciálne aminokyseliny, ktoré si samo vyrobiť nedokáže. Je zároveň bohaté na dobre vstrebateľné železo a vitamín B12.<span class=\"p-label\">Ako často mäso jesť?</span>Hoci je mäso skvelým zdrojom bielkovín, na tanieri Slovákov sa objavuje až príliš často. Veľa ľudí si bez neho nedokáže predstaviť jediné jedlo. Rovnako ako iné potraviny, ani mäso by sa však nemalo jesť trikrát denne. Striedajte ho s rybami, vajcami, mliečnymi výrobkami a aj rastlinnými bielkovinami (strukoviny, tofu, seitan alebo tempeh).",
            more: "Ryby sú tiež mäso, a navyše poriadne zdravé. V jedálničku by sa preto mali objaviť aspoň 2× do týždňa.",
          },
          {
            title: "I bez mäsa sa to dá!",
            text: "Že jedlo bez mäsa nezasýti? Omyl. Bezmäsité jedlo môže byť rovnako sýte a výživné, len je potrebné doplniť bielkovinu z iného zdroja. Ako na to?<span>Na špagety s paradajkami pridajte ako zdroj bielkovín mozzarellu light.</span><span>Cestovinový alebo zeleninový šalát doplňte strukovinami a vajíčkom.</span><span>Do zeleninovej polievky vmiešajte pred podávaním konzervovanú fazuľu cannellini.</span>",
            box: "<p>Na začiatok skúste tu a tam nahradiť mäso iným zdrojom bielkovín. A až sa novými receptami a pestrými chuťami doslova „prehryziete“, snažte sa každý týždeň jeden až dva dni mäso úplne vynechať. </p>",
          },
          {
            title: "Prečo jesť ryby? ",
            text: "<span class=\"p-label\">Obsahujú kvalitné a dobre stráviteľné bielkoviny.</span>Rybí tuk smelo konkuruje zdravým rastlinným olejom. Obsahuje omega-3 mastné kyseliny, ktoré slúžia ako prevencia srdcovo-cievnych ochorení. Vitamín D je zásadný pre zdravé kosti a zuby, zároveň však pre imunitu. Jód v morských rybách je dôležitý pre správnu funkciu štítnej žľazy, nervovú sústavu aj trávenie potravy.<span class=\"p-label\">Kvalita miesto kvantity</span>Keď si mäso doprajete menej často, prospejete našej planéte, ktorú nadmerná produkcia mäsa zaťažuje. Môžete taktiež viac premýšľať o pôvode mäsa a kúpiť to s označením BIO. Zákon o ekologickom poľnohospodárstve dbá na pohodu zvierat a kvalitu ich života. BIO chovy musia dodržať oveľa prísnejšie požiadavky na veľkosť výbehu, pôvod krmiva aj liečivá, ktoré smie chovateľ používať."
          }
        ]
      },
      {
        img: "",
        id: 9,
        position: "",
        back: "Späť",
        next: "Ďalej",
        steps: [
          {
            title: "Voda je základ života",
            text: "Tekutiny sú nutné pre fungovanie všetkých procesov v ľudskom tele a správnu funkciu orgánov, predovšetkým obličiek. Dostatočným pitím predchádzate aj bolestiam hlavy, nesústredenosti a únave.",
            text_bottom: "<span class=\"p-label\">Koľko vody denne vypiť?</span>Zdravý človek by mal vypiť približne 1,5–2 litre denne. Rovnako ako jesť, ani piť však nepotrebujeme všetci rovnaké množstvo. Potreba tekutín sa líši v závislosti od pohlavia, veku, telesnej konštitúcie aj množstva pohybu a teploty okolia. Iné množstvo vody potrebujete piť v zime a iné v júlovom teple.",
            more: "Pite v priebehu celého dňa, nie až keď cítite smäd. Smäd je znamenie, že vaše telo volá po doplnení tekutín."
          },
          {
            title: "Voda s citrónom nie je zázrak",
            text: "Voda s citrónom nepomáha spaľovať tuky, nezrýchli metabolizmus ani telu inak neprospieva. Naopak, veľa ľudí môže po jej popíjaní trpieť tráviacimi ťažkosťami a pálením záhy. Za celodenné pitie citrónovej vody vás nepochváli ani zubár. Kyslý nápoj poškodzuje zubnú sklovinu a prispieva k vzniku zubného kazu.",
            more: "Základom pitného režimu by mala byť čistá neochutená voda. Doplniť ju môžete nesladeným čajom alebo nesladenou kávou.",
          },
          {
            title: "Ako zlepšiť pitný režim",
            text: "Často si na pitie spomeniete, až keď vás začne bolieť hlava? Nie ste sami. Čo robiť, aby ste na pitie v priebehu dňa nezabúdali?<ul><li>Hneď po prebudení vypite pohár vody.</li><li>Obstarajte si opakovane použiteľnú fľašu, ktorá sa vám páči a budete z nej piť radi.</li><li>Fľašu používajte nielen v práci a v škole, ale aj doma. Budete mať lepší prehľad o tom, koľko ste už vypili.</li></ul>",
            box: "<p>Ak vám samotná voda nechutí, pridajte do nej bazalku, mätu, medovku alebo pár kúskov mrazeného ovocia.</p>",
          },
          {
            title: "Káva neodvodňuje",
            text: "Jeden z obľúbených mýtov spojených s kávou je tvrdenie, že dehydratuje. V bežnom množstve a u zdravých osôb to však neplatí. Napriek tomu nezabudnite kávu doplniť pohárom čistej vody, z jednej malej šálky espressa totiž príliš tekutín nezískate.<span class=\"p-label\">Pozor (nielen) na pridané cukry</span>Sladené limonády, ochutené minerálky a džúsy pite len výnimočne. Obsahujú veľké množstvo jednoduchých cukrov a svojím zložením neprospievajú ani zubnej sklovine.",
          },
        ]
      }
    ]
  }
}

export default data;