import React from "react";
import BasicsCard from './BasicsCard'

export default function Basics( {data} ) {

  const basicsCards = data.cards.map( item => {
    return <BasicsCard key={ item.title } data={ item } />
  })

  return(
    <section className="basics">
    <div className="container">
      <h2 className="h1">{ data.title }</h2>
      <ul>
        { basicsCards }
      </ul>
    </div>
  </section>
  )
}