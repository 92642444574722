const data = {
  banner: {
    title: "ROZEHRAJTE TALÍŘ BARVAMI A&nbsp;<span>HRAJTE O&nbsp;SKVĚLÉ VÝHRY!</span>",
    text: "Soutěž probíhá od 1. 2. do 15. 3. 2023<br />ve všech prodejnách na území České republiky.",
    image: "images/layout/banner-1.png",
    image_2: "images/layout/banner-2.png",
    button: "Chci soutěžit!",
    url: "https://www.bonduelle-soutez.cz/"
  },
  intro: {
    title: "Rozehrajte talíř barvami",
    subtitle: "Objevte s Bonduelle recept na pestrý život",
    text: "Podívejte se pod pokličku zdravému stravování. Na našem virtuálním talíři pro vás společně s inženýrkou výživy a kvality potravin Karolínou Fourovou servírujeme šťavnaté informace o všem, co by nemělo ve správně vyváženém jídelníčku chybět. Tak s chutí do toho!",
    text_up: "Podívejte se pod pokličku zdravému stravování. Na našem virtuálním talíři servírujeme šťavnaté informace o všem, co by nemělo ve správně vyváženém jídelníčku chybět. Tak s chutí do toho!",
    image: "images/layout/logo.png",
  },
  video: {
    button: "Ještě více receptů a inspirace",
    url: "https://www.bonduelle.cz/recepty-domu",
    cards: [
      {
        title: "Barevné a vyvážené jídlo do krabičky",
        coverImage: "images/layout/video-1.jpg",
        videoUrl: "video-1.mp4"
      },
      {
        title: "Jak jíst<br />více luštěnin",
        coverImage: "images/layout/video-2.jpg",
        videoUrl: "video-2.mp4"
      },
      {
        title: "Jak mít v jídelníčku více vlákniny",
        coverImage: "images/layout/video-3.jpg",
        videoUrl: "video-3.mp4"
      },
      {
        title: "Vaření<br />bez plýtvání",
        coverImage: "images/layout/video-4.jpg",
        videoUrl: "video-4.mp4"
      }
    ],
    author: {
      title: "Karolína Fourová radí",
      text: "Inženýrka v oboru Kvality potravin a výživy<br />a dvojnásobná vítězka soutěže Foodblog roku 2021 a 2022.",
      image: "images/layout/author.jpg"
    }
  },
  basics: {
    title: "Bez základů to nejde",
    cards: [
      {
        title: "Bílkoviny",
        text: "Bez bílkovin by neexistoval pohyb. Jsou základním stavebním materiálem svalů, ...",
        button: "Více informací",
        url: "#",
        popup: {
          title: "Bílkoviny",
          popupType: "protein",
          subtitle_1: "Bílkoviny nejsou jen potravou pro svaly",
          text_1: "Bez bílkovin by neexistoval pohyb. Jsou základním stavebním materiálem svalů, najdete je v kostech i šlachách. Mezi bílkoviny ale patří i hormony, které lidské tělo potřebuje pro trávení veškeré potravy, a také hormon inzulin, který pomáhá udržovat zdravou hladinu cukru v krvi.",
          subtitle_2: "Kolik bílkovin bychom měli jíst?",
          text_2: "Oficiální doporučení na množství bílkovin (nebo také proteinů) v jídelníčku je 0,8–1 g na 1 kg váhy člověka. Nadměrné porce bílkovin, které můžeme občas vidět u sportovců, tělo nezvládne na budování svalů využít. Plnohodnotné bílkoviny jsou důležité i ve výživě dětí.",
          extra_1:"Nevyužité bílkoviny lidské tělo vyloučí. Není to ale jen tak. Jejich nadbytek musí zpracovat ledviny, které tak dostanou pořádně zabrat.",
          subtitle_img: "Jak umíme využívat bílkoviny",
          image_1: "images/layout/protein-1.png",
          image_2: "images/layout/protein-2.png",
          subtitle_3: "Kde bílkoviny najdeme",
          col_1: "Z bílkovin živočišného původu využijeme cca 70 %",
          col_2: "Z bílkovin rostlinného původu využijeme cca 40 %",
          text_3: "Bílkoviny jsou složeny z esenciálních aminokyselin. Lidský organismus je ale sám vyrobit neumí, a proto je musí přijímat z jídla. Žádnou živinu ale nedokážeme vstřebat beze zbytku. Některé bílkoviny umí lidské tělo využít lépe než jiné.",
          extra_2: "Nejkvalitnější bílkovinou vůbec je mléčná bílkovina (kasein) a bílkovina vaječného bílku. Mezi nejlépe vstřebatelné rostlinné bílkoviny patří sójová, hrachová a pšeničná.",
          less: "Zpět"
        }
      },
      {
        title: "Sacharidy",
        text: "Energie pro tělo i mozek. Tak by se daly ve zkratce popsat sacharidy. Jsou nezbytným ...",
        button: "Více informací",
        url: "#",
        popup: {
          popupType: "sugar",
          title: "Sacharidy",
          subtitle_1: "Sacharidy pohánějí tělo",
          text_1: "Energie pro tělo i mozek. Tak by se daly ve zkratce popsat sacharidy. Jsou nezbytným palivem pro celý náš organismus a všechny procesy v něm. Sacharidy jsou jednou z nejdůležitějších součástí zdravého jídelníčku.",
          subtitle_2: "Kolik sacharidů bychom měli jíst?",
          text_2: "Jak velký podíl by měly v jídelníčku zaujímat sacharidy? Zhruba 55–60 % veškeré přijaté energie. Největší zastoupení by měly mít sacharidy komplexní. Ty lidský organismus tráví pomalu a energii z nich získáváme postupně. Vyvarovat bychom se naopak měli nadměrné konzumace jednoduchých a přidaných cukrů, které najdeme nejen ve sladkostech, ale i v ochucených mléčných výrobcích nebo slazených limonádách.",
          extra_1:"Není sacharid jako sacharid. Jedny jsou prospěšné a v jídelníčku by měly mít jasnou převahu, jiné jsou spíše pro potěšení duše.",
          subtitle_img: "Kde je najdeme",
          text_img: "Bohatým zdrojem „těch správných“ komplexních sacharidů jsou:",
          col_1: "Obiloviny a výrobky z nich",
          col_1_2: "<li>Mouka</li><li>Pečivo</li><li>Těstoviny</li><li>Snídaňové cereálie a kaše</li>",
          image_1: "images/layout/carbs-2.png",
          col_2: "Luštěniny",
          image_2: "images/layout/carbs-1.png",
          image_3: "images/layout/carbs-3.png",
          col_2_2: "Brambory",
          subtitle_3: "Ne všechny jednoduché cukry jsou špatné",
          text_3: "Jednoduchý cukr je také fruktóza (přirozeně se vyskytující ovocný cukr) nebo laktóza (přirozeně se vyskytující mléčný cukr). Ovoci nebo neochuceným mléčným výrobkům se kvůli tomu ale nevyhýbejte. Cukry v těchto potravinách doprovází celá řada prospěšných živin, důležitých minerálních látek a vitaminů.",
          less: "Zpět"
        }
      },
      {
        title: "Tuky",
        text: "Tuky jsou jednou ze tří základních živin. Lidské tělo je potřebuje jako zdroj energie, ale také ...",
        button: "Více informací",
        url: "#",
        popup: {
          title: "Tuky lidské tělo rádo",
          popupType: "fat",
          subtitle_1: "Tuky lidské tělo rádo",
          text_1: "Tuky jsou jednou ze tří základních živin. Lidské tělo je potřebuje jako zdroj energie, ale také na stavbu buněk a pro správnou funkci srdce a cév. Důležitou roli hrají i při výrobě hormonů a vstřebávání některých vitaminů.",
          subtitle_2: "Kolik tuků bychom měli jíst?",
          text_2: "Tuky by v jídelníčku měly tvořit asi 20–35 % z celkové přijaté energie. Konkrétní množství ale závisí na vaší tělesné konstituci, pohlaví, množství pohybu, ale i na tom, zda je vaším cílem přibrat, nebo naopak pár kilo zhubnout.",
          subtitle_3: "Není tuk jako tuk",
          text_3: "Dvě věci mají všechny tuky společné – mají vysoký obsah kalorií a jsou složeny z mastných kyselin. Jsou ale tuky vhodnější a tuky méně vhodné. Vhodnější nenasycené tuky pomáhají mimo jiné snižovat hladinu cholesterolu a riziko srdečních onemocnění. Méně vhodné nasycené tuky dělají přesný opak.",
          subtitle_4: "Ideální skladba tuků v jídelníčku",
          text_4: "Nejméně 2/3 nenasycené mastné kyseliny (rostlinné tuky a ryby)<br />Maximálně 1/3 nasycené mastné kyseliny (živočišné tuky a tropické oleje)",
          subtitle_img: "Zdroje tuků kolem nás",
          image_1: "images/layout/fat-1.svg",
          col_1: "<span>Nenasycené mastné kyseliny:</span><span>rostlinné oleje, ořechy, semínka a rybí tuk</span>",
          image_2: "images/layout/fat-2.svg",
          col_2: "<span>Nasycené mastné kyseliny:</span><span>tučné maso, máslo, sádlo, tučné mléčné výrobky a tropické oleje (kokosový, palmový)</span>",
          less: "Zpět"
        }
      },
      {
        title: "Vláknina",
        text: "O vláknině se příliš často nemluví, v jídelníčku přitom hraje jednu z hlavních rolí. ...",
        button: "Více informací",
        url: "#",
        popup: {
          title: "Vláknina",
          popupType: "fiber",
          subtitle_1: "Zázrak jménem vláknina",
          text_1: "O vláknině se příliš často nemluví, v jídelníčku přitom hraje jednu z hlavních rolí. Pomáhá s trávením, jelikož vyživuje přátelské střevní bakterie a jako „kartáč“ čistí střevo, snižuje hladinu cholesterolu a přispívá k udržení normální hladiny cukru v krvi. Vláknina také prodlužuje pocit sytosti.",
          subtitle_2: "Kolik vlákniny bychom měli jíst?",
          text_2: "S vlákninou je to jednoduchá matematika. Dospělý člověk by jí měl sníst přibližně 30 gramů denně. U dětí zjistíte orientační potřebu vlákniny jednoduchým výpočtem. Stačí, když k věku dítěte připočtete číslovku 5.",
          extra_1:"Nadbytek vlákniny v jídelníčku je stejně nežádoucí jako její nedostatek. Když je jí příliš, dochází k horšímu vstřebávání některých živin, které slouží jako zdroj energie (bílkoviny a esenciální aminokyseliny), ale také vápníku nebo zinku.",
          subtitle_img: "Vlákninu dělíme do tří kategorií",
          col_1: "Rozpustná",
          col_2: "Polorozpustná",
          col_3: "Nerozpustná",
          subtitle_3: "Kde vlákninu hledat?",
          text_3: "Bohatými zdroji nerozpustné vlákniny jsou celozrnná mouka a výrobky z ní, obilné vločky, luštěniny, ovoce a zelenina, semínka i ořechy. V ovoci a zelenině naopak najdeme více vlákniny rozpustné. Vláknina polorozpustná stojí někde uprostřed a jejím zdrojem jsou například ovesné vločky nebo některé ovoce. Neexistuje vláknina lepší a horší. Všechny výše zmíněné druhy potřebujeme a v jídelníčku by měly mít své místo.",
          less: "Zpět"
        }
      }
    ]
  },
  cards: {
    title: "Co nevíte o mražení a konzervování",
    cards: [
      {
        image:"images/layout/card-1.png",
       title: "Jako od farmáře",
       text: "<strong>Zelenina Bonduelle je pěstována ve své přirozené sezóně a v oblastech, které jsou pro danou plodinu nejvhodnější.</strong> Aby byla zaručena nejlepší chuť, barva i textura a zelenina si zachovala maximum vitaminů a minerálních látek, je sklizena v plné zralosti a zpracována do 5 hodin od sklizně." 
      },
      {
        image:"images/layout/card-2.png",
        title: "Mráz, který šokuje",
        text: "V Bonduelle je omytá a očištěná zelenina <strong>blanšírována na páře</strong> a následně šokově zmrazená proudem vzduchu o teplotě -30 °C. Zelenina se zmrazí rychle během několika minut. Díky tomu se uvnitř plodu při mražení nevytvoří veliké ledové krystaly jako při pomalém domácím mražení při vyšší teplotě. <strong>Zelenina tak zůstává neporušená a uzamkne v sobě maximum vitaminů, chuti i šťavnatosti.</strong>" 
       },
       {
        image:"images/layout/card-3.png",
        title: "Každý pátý Čech si nesprávně myslí, že jsou konzervy škodlivé.",
        text: "Nejčastější obavy plynou z přítomnosti velkého množství tzv. „éček“ a konzervantů, které ale s konzervou nemají nic společného. <strong>Slovo konzerva</strong> nenese svůj název proto, že by byla plná konzervantů, ale <strong>podle procesu konzervace, což je způsob uchovávání potravin.</strong><span>Data vycházejí z průzkumu, který pro značku Bonduelle realizovala agentura STEM/MARK v roce 2021.</span>" 
       },
       {
        image:"images/layout/card-4.png",
        title: "Tajemství konzervace",
        text: "Pokud nejsou v konzervách konzervanty, jak je tedy možné, že je jejich trvanlivost tak dlouhá? Tajemstvím je <strong>kombinace důkladného tepelného ošetření, dobře uzavřeného balení a jemně slaného nálevu, který působí jako přirozený konzervant</strong> většiny druhů zeleniny a luštěnin od Bonduelle. Díky tomu vydrží konzervy měsíce i roky bez konzervačních látek." 
       },
       {
        image:"images/layout/card-5.png",
        title: "Vždy po ruce",
        text: "<strong>Konzervovanou zeleninu a luštěniny využívá ve své kuchyni 94 % Čechů</strong>, kteří si pochvalují především jednoduchost a rychlost přípravy. Podobně praktické jsou i mražené produkty Bonduelle. <strong>Mraženou zeleninu nezapomeňte před konzumací vždy tepelně opracovat.</strong> Návod na zadní straně obalu vám poradí, jak správně postupovat." 
       }
    ]
  },
  footer: {
    title: "Bonduelle, již od roku 1853",
    text: "Bonduelle je rodinná společnost, která pracuje již sedm generací v&nbsp;oblasti zemědělské produkce, která dbá o&nbsp;šetrný přístup k&nbsp;půdě a&nbsp;jejím pracovníkům. Bonduelle dbá o efektivní, inteligentní a&nbsp;podpůrnou agroekologii, která myslí na&nbsp;budoucnost. Heslo společnosti – Příroda je naše budoucnost.",
    social: [
      {
        url: "https://www.facebook.com/bonduelle.cz",
        icon: "fb"
      },
      {
        url: "https://www.instagram.com/bonduelle.cz/",
        icon: "ig"
      },
      {
        url: "https://www.youtube.com/channel/UCkfv1NICOqgj2mJ4lMWp43Q",
        icon: "ytb"
      }
    ]
  },

  plate: {
    arrow: "Klikni zde",
    popups: [
      {
        img: "",
        id: 1,
        position: "",
        back: "Zpět",
        next: "Dále",
        steps: [
          {
            title: "Zelenina je pestrá nejen na tvary a barvy",
            text: "Každodenní konzumace ovoce a zeleniny je pro lidské tělo nenahraditelná. Vitaminy a další prospěšné látky z nich získává v té nejlepší možné formě. Žádný vitaminový doplněk stravy nenahradí pravidelnou konzumaci zeleniny a ovoce z celého barevného spektra.",
            more: "Denně bychom měli sníst přibližně 300–400 g zeleniny.",
          },
          {
            title: "Mražená není horší než čerstvá",
            text: "Zelenina, která je sklizená předčasně nebo čeká ovadlá na pultech obchodů, obsahuje vitaminů méně než zelenina čerstvá a v plné síle. Jiná se ale často v obchodech nedá sehnat, především v zimě. Díky Bonduelle si můžete vychutnat zeleninu plnou chuti kdykoli potřebujete. Díky sklizni v plné zralosti a moderním postupům mražení si zachovává vlastnosti čerstvé zeleniny.",
            more: "Zeleninu konzumujte po celý rok, bez ohledu na roční období.",
          },
          {
            title: "Zeleninové tipy a triky",
            text: "Čerstvou zeleninu omývejte až těsně před konzumací. Jinak hrozí, že smyjeme přirozený ochranný film nebo potravinářský vosk, který plody chrání. Neomytá zelenina vám v lednici vydrží mnohem déle.<br /><br />Mraženou zeleninu rozmrazujte pod studenou tekoucí vodou a v původním balení. Po rozmrazení ji vždy tepelně opracujte. Stačí ji vložit na 2–3 minuty do vroucí vody. Pokud si nevíte rady, podívejte se na instrukce na zadní straně obalu."
          },  
          {
            title: "Víte, jak správně skladovat zeleninu?",
            text: "Přihrádka na ovoce a zeleninu v lednici je pro skladování většiny druhů zeleniny jako stvořená. Uvnitř ní je teplota 0–4 °C a také vetší vlhkost, díky které zelenina rychle nezvadne. V horních patrech lednice, kde je teplota vyšší, uchovávejte například brambory nebo papriky.",
            box: "<span>Zelenina, která patří do lednice:</span><p>kořenová zelenina, květák, brokolice, saláty</p><span>Zelenina, která do lednice nepatří:</span><p>rajčata, okurky, lilek, cukety, dýně</p>"
          },  
          {
            more: "Kvalitní mražená zelenina je vhodnou alternativou zeleniny čerstvé.",
            text_bottom: "Není žádným překvapením, že nejvíce vitaminů, minerálních látek a vlákniny obsahuje zelenina čerstvá, případně kvalitní zelenina mražená. Jakákoliv tepelná úprava znamená menší či větší ztrátu vitaminů. Nejšetrnější je příprava zeleniny v páře, nejvíce vitaminů ztrácí při dlouhém vaření ve vodě.<br /><br />Proč chutná kvalitní mražená zelenina lépe než zmrazená doma? V Bonduelle se využívá šokového mražení proudem vzduchu o teplotě -30 °C. Teplota v domácím mrazáku je mnohem vyšší a zmrazování tak trvá několik hodin. Zelenina tak přichází o část vitaminů i chuti a při rozmrazení ztratí více šťávy."
          }
        ]
      },
      {
        img: "",
        id: 6,
        position: "",
        back: "Zpět",
        next: "Dále",
        steps: [
          {
            title: "Bílkoviny nejsou jen v mase",
            text: "Obiloviny a luštěniny nejsou pouze zdrojem sacharidů a vlákniny. Obsahují také rostlinné bílkoviny. Ty sice nejsou tak snadno vstřebatelné a využitelné jako živočišné, s trochou kreativity ale zvládnete vykouzlit sytá a výživná jídla i bez masa, vajec a mléka.",
            more: "Dobrý rostlinný „jogurt“ by měl obsahovat více než 3 gramy bílkovin ve 100 gramech produktu.",
          },
          {
            title: "Vsaďte na luštěniny",
            text: "Rostlinné bílkoviny se schovávají například v luštěninách. V bezmasých receptech lze díky nim množství bílkovin snadno zvýšit.<ul><li>Vyzkoušejte luštěninové těstoviny. </li><li>Těstovinový či zeleninový salát doplňte konzervovanou cizrnou. </li><li>Z bílých nebo červených fazolí vykouzlete skvělé pomazánky. </li><li>Z červených fazolí upečte vláčné čokoládové brownies na svačinu.</li></ul>",
            more: "Zdrojem vápníku může být nečekaně tofu. Na srážení sójové bílkoviny se u některých výrobků používají vápenaté soli.",
          },
          {
            title: "Pořádně sytý oběd",
            text: "Aby vás hlavní jídlo opravdu a na dlouhou dobu zasytilo, mělo by obsahovat přibližně 20 gramů bílkovin. Co si ale pod tímto množstvím představit v bezmasém jídelníčku?<ul class=\npopup-list\n><li>80 gramů luštěnin v suchém stavu</li><li>250 gramů konzervovaných fazolí nebo čočky</li><li>150 gramů tofu</li><li>100 gramů seitanu</li></ul>",
          },
          {
            title: "Rostlinně alespoň jednou do týdne",
            text: "V jídelníčku každého z nás by se měl vyskytovat alespoň jeden či dva bezmasé dny týdně. Nejenže tak pečujete o naši planetu, ale váš jídelníček bude pestřejší, barevnější a objevíte mnoho nových a překvapivých chutí.<span class=\"p-label\">Není jogurt jako jogurt</span>Ne každý rostlinný výrobek v kelímku, který se jako jogurt tváří, je opravdu vhodnou náhradou jogurtu. Ten správný musí mít ve složení probiotickou kulturu. Jen takový výrobek podporuje trávení a má pozitivní vliv na imunitu. Ostatní výrobky jsou spíše rostlinné dezerty. Často obsahují velké množství tuku i přidaného cukru."
          },
          {
            title: "Pestrost je základ",
            text: "Poskládat plnohodnotný rostlinný jídelníček je sice těžší, ale jde to. Abyste v jídelníčku měli bílkovin dostatek a zároveň toho správného složení, je potřeba různé rostlinné zdroje vzájemně kombinovat a na talíři je střídat.",
            more: ""
          }
        ]
      },
      {
        img: "",
        id: 3,
        position: "",
        back: "Zpět",
        next: "Dále",
        steps: [
          {
            title: "Tuky z říše rostlin",
            text: "Rostlinné oleje, ořechy i semena. V našem jídelníčku by měly převažovat rostlinné tuky nad živočišnými. Obsahují prospěšné nenasycené mastné kyseliny a esenciální mastné kyseliny omega-3 a omega-6, které si tělo neumí samo vyrobit.<span class=\"p-label\">Přínosy omega-3 mastných kyselin </span><ul><li>Starají se o růst a kvalitu vlasů a kůže i správnou funkci mozku. </li><li>Napomáhají snižovat krevní tlak a „zlý“ LDL cholesterol. </li><li>Regulují hladinu tuků v krvi a snižují pravděpodobnost vzniku onemocnění srdce a cév. </li></ul>",    
          },
          {
            title: "Zdroje mastných kyselin",
            text_bottom: "<span class=\"p-label\">Ne všechno rostlinné je zdravé</span>Pocházejí sice z rostlinné říše, ale složením se podobají spíše živočišným tukům. Řeč je o kokosovém a palmovém oleji. Kokosový olej obsahuje přibližně 90 % nasycených mastných kyselin, palmový olej 50 %. Nasycené tuky by se na jídelníčku měly vyskytovat zřídka. Jsou-li v nadbytku, přispívají ke kardiovaskulárním onemocněním. Tropické oleje proto konzumujte výjimečně a v menším množství. ",    
            box: "<span>Zdroje mastných kyselin</span><span>Omega-3:</span><p>tučné ryby, řepkový olej, vlašské ořechy a lněný olej</p><span>Omega-6:</span><p>ořechy, semínka, rostlinné oleje</p>"
          },
          {
            title: "Snídaňový hit – avokádo",
            text: "Avokádo se stalo hitem snídaňových menu. Toto ovoce je bohaté na vlákninu a rostlinné tuky. Zdravý tuk je ale stále tuk. Na jednu porci stačí ¼ plodu.<span class=\"p-label\">Chytré kombinace na talíři</span>Nejen u avokáda a olejů je dobré mít příjem tuků pod kontrolou. Jak na to a na co si dát pozor? V oblíbeném salátu s rajčaty a mozzarellou sáhněte po sýru „light“ a ušetřený tuk doplňte lžící olivového oleje. Ořechového krému stačí do snídaňové ovesné kaše přidat jen jedna kávová lžička. Ořechů k svačině do jogurtu bude stačit jen pár kousků.",    
          },
          {
            title: "Na pánev či do salátu?",
            text: "Ne všechny tuky jsou ale stejně zdravé v salátu i při smažení na pánvi. Panenské a za studena lisované oleje se hodí ve studené kuchyni. Při smažení a vysokých teplotách se ale rychle přepalují. Na tepelnou úpravu jsou proto lepší rafinované oleje, které vydrží i vyšší teploty.<br />Nejlepší složení mastných kyselin i vlastnosti pro tepelnou úpravu má obyčejný řepkový olej. Ve studené kuchyni ochutnejte kromě skvělého panenského olivového oleje i méně známé za studena lisované, jako je makový, lněný nebo panenský řepkový.",    
          },
        ]
      },
      {
        img: "",
        id: 4,
        position: "",
        back: "Zpět",
        next: "Dále",
        steps: [
          {
            title: "Šťavnaté a zdravé ovoce",
            text: "Ovoce je plné vitaminů a minerálních látek. To ví většina z nás. Málo se ale ví, že je také důležitým zdrojem tekutin. V jídelníčku pomáhá zvýšit množství rozpustné i nerozpustné vlákniny. Zároveň obsahuje nenápadné, ale velmi důležité fytochemikálie (látky vyskytující se v rostlinách), z nichž některé mohou mít příznivý účinek na lidské zdraví.",
            more: "Denně bychom měli sníst přibližně 100–200 g ovoce, což odpovídá například střednímu jablku, pomeranči nebo banánu.",
          },
          {
            title: "Na ovoce zčerstva",
            text: "Nejvíce vitaminů a vlákniny získáte z čerstvého ovoce, které sníte „postaru“ vcelku. Oblíbená smoothie jsou sice pohodlný způsob, jak zařadit ovoce do jídelníčku, ale z nutričního hlediska tolik výhodná nejsou. Mixováním se potřebná vláknina rozseká na menší části a odšťavňováním o ni přicházíte úplně. Pokud navíc smoothie ihned nevypijete, působením světla a kyslíku ztrácí vitaminy. Po několika hodinách pak v láhvi zbude v podstatě jen voda a ovocný cukr.",
            box: "<p>Tip: Ideální ovocná svačinka Samotné&nbsp;ovoce není dostatečná svačina. V kombinaci s neochuceným tvarohem, kefírem anebo jogurtem zasytí mnohem déle.</p>"
          },
          {
            title: "Čtyřikrát více jednoduchého cukru",
            text: "Ovocné šťávy a smoothie mohou být problém také v redukčním jídelníčku. V jedné sklenici se schová i několik kusů ovoce, které byste najednou pravděpodobně nesnědli. Taková nenápadná sklenice smoothie nebo džusu pak může oproti misce jogurtu s vločkami a ovocem obsahovat až čtyřikrát více jednoduchého cukru.<span class=\"p-label\">Ovoce do jídelníčku patří</span>Jedním z nejčastějších mýtů spojených s hubnutím je, že ovoce kvůli cukru do redukčního jídelníčku nepatří nebo by se mělo konzumovat jen v určitou denní dobu. V rozumném množství ovoce patří do jídelníčku každého! Není totiž pouze zdrojem jednoduchých cukrů. Organismus z něj získává také vlákninu, minerální látky, vitaminy a další cenné živiny.",
          },
          {
            title: "V barvách duhy",
            text: "Každá barva ovoce obsahuje trochu jiné prospěšné látky. Konzumací různě barevných druhů ovoce si zajistíte nejen přísun rozličných vitaminů, ale i cenných rostlinných látek zvaných flavonoidy.<br /><br /> Fialové a tmavě červené plody obsahují barviva antokyany. Výzkumy naznačují, že mají řadu pozitivních účinků, jako je například prevence kardiovaskulárních onemocnění nebo rakoviny. Jablka jsou zdrojem flavonoidu kvercetinu, který působí protizánětlivě a výzkumy naznačují, že pomáhá v prevenci kardiovaskulárních a některých nádorových onemocnění. Pomeranče a grapefruity pak jsou zdrojem naringinu, který pomáhá zvyšovat pevnost a elasticitu cévních stěn.",
            more: "Samotné ovoce není dostatečná svačina. V kombinaci s neochuceným tvarohem, kefírem anebo jogurtem zasytí mnohem déle."
          },
          {
            title: "Víte, jak správně skladovat ovoce?",
            more: "Plody, které patří do lednice:<br />jablka, hrušky, švestky, broskve, jahody, maliny, borůvky, pomeranče",
            more_bottom: "Plody, které do lednice nepatří:<br />avokádo, banány, grapefruity, citrony"
          },
        ]
      },
      {
        img: "",
        id: 5,
        position: "",
        back: "Zpět",
        next: "Dále",
        steps: [
          {
            title: "Zdravý zázrak jménem luštěniny",
            text: "Luštěniny si místo v jídelníčku právem zaslouží. Chutnají skvěle a jsou bohaté na sacharidy, bílkoviny, vitaminy skupiny B a vlákninu. Právě ta v jídelníčku Čechů chybí. Většina nezvládá sníst ani polovinu doporučené denní dávky vlákniny, tedy 30 gramů.",
            more: "Obsah základních živin, vlákniny i vitaminů je ve všech druzích luštěnin přibližně stejný.",
            box: ""
          },
          {
            title: "Luštěniny nemusí nadýmat",
            text: "Mnoho lidí si luštěniny spojuje s nadýmáním, proto je raději z jídelníčku úplně vyřazuje. Ochuzovat se o tak vydatné a nutričně bohaté potraviny je ale věčná škoda. Zvlášť když se dá na nestravitelné oligosacharidy, které tuto nepříjemnost způsobují, snadno vyzrát. <strong>Chcete vědět jak?</strong>",
            more: "",
            box: "<span>Namočte je</span><p>Luštěniny přes noc namočte do vody a tu poté slijte. Na vaření použijte vodu novou. Tímto způsobem snadno snížíte obsah oligosacharidů téměř o polovinu.</p>"
          },
          {
            title: "Povolejte na pomoc mixér",
            text: "Nepříjemným pocitům nadýmání lze zabránit také mixováním. Při přípravě luštěninových pomazánek a dipů v mixéru oligosacharidy mechanicky rozmělníme. Stanou se tak stravitelnějšími.",
            more: "",
            box: "<span>Vyzkoušejte klíčení</span><p>Při klíčení luštěnin získává klíček energii právě z nestravitelných oligosacharidů. Jejich obsah se tak v naklíčené luštěnině sníží až o 80 %.</p>"
          },
          {
            title: "Luštěniny místo masa",
            text: "Živočišné a rostlinné bílkoviny na talíři střídejte. Maso můžete občas nahradit luštěninami, jako je čočka, fazole nebo cizrna.",
            more: "Luštěniny by se měly v jídelníčku objevovat nejméně 2× týdně.",
            box: "<p>V 80 gramech suchých nebo 250 gramech vařených či konzervovaných luštěnin se skrývá 20 gramů bílkovin. To je přibližné množství, které by mělo obsahovat jedno hlavní jídlo.</p>"
          },
          {
            title: "Bílkovinový match",
            text: "Ne všechny rostlinné bílkoviny mají stejné složení. Ve vyváženém rostlinném jídelníčku proto vzájemně kombinujte luštěninové bílkoviny například s pšeničnými.",
            more: "",
            box: "<span>Chcete vědět jak?</span><p>Salát z bulguru či těstovin doplňte fazolemi nebo cizrnou.</p><p>Pomazánku z fazolí namažte na pečivo.</p><p>Uvařte špagety s „boloňskou“ omáčkou z červené čočky.</p>"
          },
          {
            title: "Konzervované zdraví",
            text: "Pokud chcete uspořit čas, sáhněte po luštěninách v konzervě. Obsah živin v konzervovaných luštěninách Bonduelle je stejný jako v těch, které uvaříte doma. Díky kvalitnímu a pečlivému procesu konzervace si navíc zachovají maximum chuti.",
            more: "",
            box: ""
          }
        ]
      },
      {
        img: "",
        id: 2,
        position: "",
        back: "Zpět",
        next: "Dále",
        steps: [
          {
            title: "Obiloviny jako základ jídelníčku",
            text: "Obiloviny tvoří základnu potravinové pyramidy. Jsou totiž nejvýznamnějším zdrojem komplexních sacharidů a vlákniny v lidské výživě. Poskytují potřebnou energii a podporují činnost trávicího traktu. Jsou také důležitým zdrojem rostlinných bílkovin v jídelníčku, vitaminů B a E.",
            box: "<span>Známé i neznámé obiloviny</span><p>Pšenice, žito, oves, rýže, ječmen</p><p>Pohanka, quinoa (merlík čilský), jáhly, čirok, amarant</p>",
          },
          {
            title: "Bílá mouka neškodí",
            text: "Navzdory své pověsti není bílá mouka nezdravá. Je pouze nutričně chudší, jelikož se mele z vyloupaných obilek bez obalů a klíčku. Celozrnná mouka se mele i s obaly a klíčkem. Obsahuje proto více vlákniny, dvojnásobek některých vitaminů, fosforu, vápníku i rostlinného železa. A také více tuku.",
            more: "Ani přílohy by neměly být jednotvárné. Každá z obilovin obsahuje jiné množství vlákniny, vitaminů i minerálních látek, proto by se na talíři měly střídat.",
          },
          {
            title: "Lepek nelepí střevo, ale těsto",
            text: "V některých obilovinách se vyskytuje také „celebrita“ ve skupině rostlinných bílkovin – lepek. Ten své jméno nezískal podle toho, že by zalepoval střevo. Lepí těsto, dělá ho pružné a tažné. Jen díky němu si můžete pochutnávat například na kynutém pečivu.<br /><span class=\"p-label\">Jak často a kolik čeho konzumovat</span> Při výběru mouky a pečiva dávejte přednost celozrnným a žitným variantám, které obsahují více vlákniny. Dobroty z bílé mouky si dopřejte pouze občas na zpestření jídelníčku.",
            more: "Osoby, které netrpí alergií či celiakií, se lepku bát nemusí.",
          }
        ]
      },
      {
        img: "",
        id: 7,
        back: "Zpět",
        next: "Dále",
        position: "",
        steps: [
          {
            title: "Překvapivá „superpotravina“",
            text: "Mléko bychom mohli snadno nazvat superpotravinou. Obsahuje některé z nejkvalitnějších bílkovin vůbec a v podstatě celé spektrum vitaminů (zejména vitaminy A, D, E, K). Pro lidské tělo navíc neexistuje lepší zdroj vápníku.<span class=\"p-label\">Jak často a kolik mléka konzumovat?</span>Každý den byste měli sníst dvě až tři porce mléčných výrobků. Co si ale pod jednou porcí představit? <span>Jeden větší kelímek jogurtu (200 gramů),</span><span> sklenice mléka, kefíru nebo jiného kysaného mléčného výrobku (250 gramů), </span><span>tři plátky tvrdého sýru (50 gramů)</span>",
            more: "Bílé mléčné výrobky jsou základ. Ochucené jogurty a mléčné dezerty, které obsahují hodně přidaného cukru, konzumujte jen občas.",
          },
          {
            title: "Mléko nezahleňuje",
            text: "Jedním z nejrozšířenějších mýtů o mléce je tvrzení, že zahleňuje. Žádný výzkum ovšem nic takového nepotvrdil. Mléko pouze vytváří na sliznicích úst a trávicího traktu ochranný film, který se během chvíle rozpustí.",
            more: "Kysané mléčné výrobky jsou zdrojem živých přátelských bakterií. Podporují trávení a mohou přispět i k silnější imunitě.",
          },
          {
            title: "Jak nahradit mléčné výrobky",
            text: "Mléko je v jídelníčku těžko nahraditelné. Při alergii na mléčnou bílkovinu nebo v rostlinném jídelníčku ale jinou možnost nemáte. Vybírejte pak takové rostlinné nápoje a „jogurty“, které se „mléčnému originálu“ co nejvíce podobají, ať už množstvím bílkovin, tuků nebo sacharidů. Na obalu výrobku hledejte také informaci, že byl obohacen vápníkem a vitaminem D.",
            more: "Jogurty s obsahem tuku 2,5–3,5 % obsahují více bílkovin a vápníku než tučnější smetanové jogurty.",
          },
          {
            title: "Laktóza není překážkou",
            text: "Intolerance laktózy není důvod, proč mléčné výrobky z jídelníčku vyřadit. Ochudit se o všechna „mléčná“ pozitiva by byla obrovská škoda. Dnes už naštěstí můžete vybírat z obrovské nabídky bezlaktózových mléčných výrobků.<span class=\"p-label\">Mléko do každého jídelníčku</span>Nechutná vám samotný bílý jogurt a přemýšlíte, jak do jídelníčku „schovat“ více mléčných výrobků? Můžete být mnohem kreativnější. <span>Z tvarohu a sýru cottage připravte bylinkový dip. </span><span>Do snídaňové ovesné kaše vmíchejte bílý jogurt nebo skyr. </span><span>Sladké palačinky nebo lívance podávejte s řeckým jogurtem a ovocem. </span><span>Oblíbenou vajíčkovou či rybičkovou pomazánku odlehčete tvarohem nebo žervé. </span>"
          }
        ]
      },
      {
        img: "",
        id: 8,
        back: "Zpět",
        next: "Dále",
        position: "",
        steps: [
          {
            title: "Cenný zdroj kvalitních bílkovin",
            text: "Maso má v jídelníčku své místo. Lidské tělo z něj získává plnohodnotné bílkoviny a esenciální aminokyseliny, které si samo vyrobit nedokáže. Je také bohaté na dobře vstřebatelné železo a vitamin B12.<span class=\"p-label\">Jak často maso jíst? </span>Přestože je maso skvělým zdrojem bílkovin, na talíři Čechů se objevuje až příliš často. Mnoho lidí si bez něj neumí představit jediné jídlo. Stejně jako jiné potraviny, ani maso by se ale nemělo jíst třikrát denně. Střídejte ho s rybami, vejci, mléčnými výrobky a také rostlinnými bílkovinami (luštěniny, tofu, seitan nebo tempeh).",
            more: "Ryby jsou také maso, a navíc pořádně zdravé. V jídelníčku by se proto měly objevit alespoň 2× do týdne.",
          },
          {
            title: "I bez masa to jde!",
            text: "Že jídlo bez masa nezasytí? Omyl. Bezmasé jídlo může být stejně syté a výživné, jen je potřeba doplnit bílkovinu z jiného zdroje. Jak na to? <span>Na špagety s rajčaty přidejte jako zdroj bílkovin mozzarellu light. </span><span>Ovocné knedlíky doplňte pořádnou porcí nastrouhaného tvrdého tvarohu. </span><span>Těstovinový nebo zeleninový salát doplňte luštěninami a vajíčkem. </span><span>Do zeleninové polévky vmíchejte před podáváním konzervované fazole cannellini.</span>",
            box: "<p>Pro začátek zkuste tu a tam nahradit maso jiným zdrojem bílkovin. A až se novými recepty a pestrými chutěmi doslova „prokoušete“, snažte se každý týden jeden až dva dny maso úplně vynechat.</p>",
          },
          {
            title: "Proč jíst ryby?",
            text: "<span class=\"p-label\">Obsahují kvalitní a dobře stravitelné bílkoviny </span>Rybí tuk směle konkuruje zdravým rostlinným olejům. Obsahuje omega-3 mastné kyseliny, které slouží jako prevence srdečně-cévních onemocnění. Vitamin D je zásadní pro zdravé kosti a zuby, ale také pro imunitu. Jód v mořských rybách je důležitý pro správnou funkci štítné žlázy, nervovou soustavu i trávení potravy.<span class=\"p-label\">Kvalita místo kvantity </span>Když si maso dopřejete méně často, přispějete naší planetě, kterou nadměrná produkce masa zatěžuje. Můžete také více přemýšlet o původu masa a koupit to s označením BIO. Zákon o ekologickém zemědělství dbá na pohodu zvířat a kvalitu jejich života. BIO chovy musí dodržet mnohem přísnější požadavky na velikost výběhu, původ krmiva i léčiva, která smí chovatel používat. "
          }
        ]
      },
      {
        img: "",
        id: 9,
        back: "Zpět",
        next: "Dále",
        position: "",
        steps: [
          {
            title: "Voda je základ života",
            text: "Tekutiny jsou nezbytné pro fungování všech procesů v lidském těle a správnou funkci orgánů, především ledvin. Dostatečným pitím předcházíte také bolestem hlavy, nesoustředěnosti a únavě̌.",
            text_bottom: "<span class=\"p-label\">Kolik vody denně vypít?</span>Zdravý člověk by měl vypít přibližně 1,5–2 litry denně. Stejně jako jíst, ani pít ovšem nepotřebujeme všichni stejné množství. Potřeba tekutin se liší v závislosti na pohlaví, věku, tělesné konstituci i množství pohybu i teplotě okolí. Jiné množství vody potřebujete pít v zimě a jiné v červencovém parnu."
          },
          {
            title: "Voda s citronem není zázrak",
            text: "Voda s citronem nepomáhá spalovat tuky, nezrychlí metabolismus ani tělu jinak neprospívá. Naopak, mnoho lidí může po jejím popíjení trpět trávicími obtížemi a pálením žáhy. Za celodenní pití citronové vody vás nepochválí ani zubař. Kyselý nápoj poškozuje zubní sklovinu a přispívá ke vzniku zubního kazu.",
            more: "Základem pitného režimu by měla být čistá neochucená voda. Doplnit ji můžete neslazeným čajem nebo neslazenou kávou.",
          },
          {
            title: "Jak zlepšit pitný režim",
            text: "Často si na pití vzpomenete, až když vás začne bolet hlava? Nejste sami. Co dělat, abyste na pití v průběhu dne nezapomínali?<ul><li>Hned po probuzení vypijte sklenici vody.</li><li>Pořiďte si znovupoužitelnou lahev, která se vám líbí a budete z ní pít rádi.</li><li>Lahev používejte nejen v práci a ve škole, ale i doma. Budete mít lepší přehled o tom, kolik jste již vypili.</li></ul>",
            box: "Pokud vám samotná voda nechutná, přidejte do ní bazalku, mátu, meduňku anebo pár kousků mraženého ovoce.",
          },
          {
            title: "Káva neodvodňuje",
            text: "Jeden z oblíbených mýtů spojených s kávou je tvrzení, že dehydratuje. V běžném množství a u zdravých osob to ale neplatí. Přesto nezapomeňte kávu doplnit sklenkou čisté vody, z jednoho malého šálku espressa totiž příliš tekutin nezískáte.<span class=\"p-label\">Pozor (nejen) na přidané cukry</span>Slazené limonády, ochucené minerálky a džusy pijte jen výjimečně. Obsahují velké množství jednoduchých cukrů a svým složením neprospívají ani zubní sklovině.",
          },
        ]
      }
    ]
  }
}

export default data;