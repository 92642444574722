import React from "react";
import { useEffect } from "react";

export default function PlatePopup( { steps, resetPopup, myClick, item}) {
  let [currentStep, setCurrentStep] = React.useState( 0 );

  function nextPopup(e) {    
    setCurrentStep( (prevState) => prevState + 1 )
    e.stopPropagation();
  }

  function hidePopups() {
    setCurrentStep( 0 );
  }

  function changeDot() {
    let dots = Array.from( document.querySelectorAll('.show .plate--popup-dot') );
    if ( dots.length < 1 ) return;
    dots.forEach(element => {
      element.classList.remove('active');
    }); 
    if ( currentStep < dots.length ) {
      dots[ currentStep ].classList.add('active');
    }
  }

  useEffect(() => {
    hidePopups(); 
  }, [resetPopup]);

  useEffect(() => {
    changeDot();
  }, [changeDot]);

  function prevPopup(e) {    
    setCurrentStep( (prevState) => prevState - 1 )
    e.stopPropagation();
  }
  
  let popupDots = [];
  let dotElement = document.createElement("span");
  dotElement.classList.add('plate--popup-dot');
  for (let index = 0; index < steps; index++) {
    if ( index === 0) {
      popupDots.push( {
        el: dotElement,
        id: index,
        classes: "plate--popup-dot active"
      } );
    }
    else {
      popupDots.push( {
        el: dotElement,
        id: index,
        classes: "plate--popup-dot"
      } );
    }
  }
  
  const renderDots = popupDots.map( item => {
    return <div key={item.id} className={item.classes} />
  })

  


  return(
    <div key={ item.id } data-id={item.id} className={"plate--part plate--part-" + item.id }>
      <img alt="" src={"images/plate/part--"+item.id+".png"} /> 
      <div className="plate--popup">
        <div onClick={ myClick } className="plate--popup-close">&#10005;</div>
        { item.steps[currentStep].title && <h2 className="h2">{ item.steps[currentStep].title }</h2> }
        { item.steps[currentStep].text && <p dangerouslySetInnerHTML={{ __html: item.steps[currentStep].text }}></p> }

        { item.steps[currentStep].more && <p dangerouslySetInnerHTML={{ __html: item.steps[currentStep].more }} className="plate--popup-more"></p> } 

        { item.steps[currentStep].box && <div dangerouslySetInnerHTML={{ __html: item.steps[currentStep].box }} className="plate--popup-box"></div> } 

        { item.steps[currentStep].more_bottom && <p dangerouslySetInnerHTML={{ __html: item.steps[currentStep].more_bottom }} className="plate--popup-more"></p> } 

        { item.steps[currentStep].text_bottom && <p dangerouslySetInnerHTML={{ __html: item.steps[currentStep].text_bottom }}></p> }

        <div className="plate--popup-controls">
          { currentStep > 0 && <div onClick={ prevPopup } className="plate--popup-prev">{ item.back }</div> }
          { currentStep < steps - 1 && <div onClick={ nextPopup } className="plate--popup-next">{ item.next }</div> }
        </div>
        <div className={"plate--popup-dots"}>
          { renderDots }
        </div>
      </div>           
    </div>
  )
}