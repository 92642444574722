import React from "react";

export default function BasicsPopupFat( { toggleCollapsed, data } ) {
  return(
    <div className="basics--popup-content">
    <h2 className="h2">{ data.title }</h2>
    { data.subtitle_1 && <h3 className="h3">{ data.subtitle_1 }</h3> }
    { data.text_1 && <p className="text--left">{ data.text_1 }</p> }
    { data.extra_1 && <p className="basics--popup-extra">
      { data.extra_1 }
    </p> }
    { data.subtitle_2 && <h3 className="h3">{ data.subtitle_2 }</h3> }
    { data.text_2 && <p className="text--left">{ data.text_2 }</p> }
    { data.subtitle_3 && <h3 className="h3">{ data.subtitle_3 }</h3> }
    { data.text_3 && <p className="text--left">{ data.text_3}</p> }
    { data.subtitle_4 && <h3 className="h3">{ data.subtitle_4 }</h3> }
    
    { data.text_4 && <p className="text--left" dangerouslySetInnerHTML={{ __html:data.text_4 }}></p> }
    { data.subtitle_img && <h3 className="h3">{ data.subtitle_img }</h3> }
    <div className="basics--popup-cols">
      <div>
        { data.image_1 && <img src={ data.image_1 } alt="alt 1" /> }
        { data.col_1 && <div dangerouslySetInnerHTML={{ __html:data.col_1 }}></div> }
      </div>
      <div>
        { data.image_2 && <img src={ data.image_2 } alt="alt 1" /> }
        { data.col_2 && <div dangerouslySetInnerHTML={{ __html:data.col_2 }}></div> }
      </div>
    </div>
    <div onClick={ toggleCollapsed } className="btn btn--green">{ data.less }</div>
  </div>
  )
} 