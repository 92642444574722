import React from "react";
import BasicsPopup from './BasicsPopup.js'

export default function BasicsCard( {data} ) {



  let [isCollapsed, setCollapsed] = React.useState(false);

  function toggleCollapsed() {
    setCollapsed( (prevState) => !prevState  )
  }

  return(
    <li>
      <article>
        <h3 className="h2">{ data.title }</h3>
        <p>{ data.text }</p>
        <div onClick={ toggleCollapsed } className="btn btn--green">{data.button}</div>
      </article>
      { isCollapsed && 
        <BasicsPopup data={ data.popup } toggleCollapsed={toggleCollapsed} />
      }
    </li>
  )
}