import React from 'react';
import './App.css';
import data_cs from './data.js'
import data_sk from './data-sk.js';
import Banner from './components/Banner.js'
import Intro from './components/Intro.js'
import Plate from './components/Plate.js'
import Video from './components/Video.js'
import Basics from './components/Basics.js'
import Cards from './components/Cards.js'
import Footer from './components/Footer.js'

function App() {
  // const queryParameters = new URLSearchParams(window.location.search)
  // const lang = queryParameters.get("lang")

  let [data, setData] = React.useState( data_sk );

  // function toggleData() {
  //   setData( data_sk );
  // }

  // React.useEffect(() => {
  //   if ( lang === 'sk') { 
  //     toggleData();
  //   }
  // }, [ lang ]);



  return (
    <div className="App">
      <Intro data={ data.intro } />
      <Plate data={ data.plate } />
      <Video data={ data.video } />
      <Basics data={ data.basics } />
      <Cards data={ data.cards } />
      <Footer data={ data.footer } />
    </div>
  );
}

export default App;
