import {React, useEffect, useRef } from 'react';
import { Player } from 'video-react';

export default function VideoCard( {data, trigger, clickEvent} ) {

  const childRef = useRef([]);

  const stopVideo = () => {
    let loopItem = childRef.current;
    if( loopItem.props.customId !== trigger ) {
      loopItem.pause();
    }
  };

  function handleClick() {
    clickEvent( childRef.current.props.customId );
  }

  useEffect(() => {
    if (trigger) {
      stopVideo();
    }
  }, [trigger]);

  return(
    <li>
      <h3 dangerouslySetInnerHTML={{ __html:data.title }} className="title--short"></h3>
      <div onClick={ handleClick } className="video-container">
        <Player ref={ childRef } customId={ data.videoUrl }  poster={ data.coverImage } playsInline ><source src={"videos/" + data.videoUrl } /></Player>
      </div>
    </li>
  )
}