import React from "react";
import PlatePopup from "./PlatePopup";
import { useState } from "react";

export default function Plate( {data} ) {

  let [opened, setOpened] = React.useState(false);

  function toggleOpen( status ) {
    setOpened( prev => prev = status );
  }
  
  function openPopup( current ) {
    let parts = Array.from( document.querySelectorAll('.plate--part'));    
    let placeholder = document.getElementsByClassName('plate--clear')[0];    
    doResetPopup(prev => prev + 1);    
    placeholder.classList.add('opened');
    toggleOpen( true );

    parts.forEach(function( item ) {
      item.classList.remove('show');
    })

    let partId = current.target.id.slice(-1);

    let currentPart = document.getElementsByClassName('plate--part-' + partId );
    currentPart[0].classList.add('show');
    current.stopPropagation();
  }

  function hoverPopupIn( current ) {
    let parts = Array.from( document.querySelectorAll('.plate--part'));    
    let placeholder = document.getElementsByClassName('plate--clear')[0];  
    
        placeholder.classList.add('opened');
        
        parts.forEach(function( item ) {
          item.classList.remove('hovered');
        })

        let partId = current.target.id.slice(-1);

        let currentPart = document.getElementsByClassName('plate--part-' + partId );
        currentPart[0].classList.add('hovered');
        current.stopPropagation();
  }
  

  function hoverPopupOut( current ) {
    let parts = Array.from( document.querySelectorAll('.plate--part'));  
    let placeholder = document.getElementsByClassName('plate--clear')[0];
    
    if( opened === false) {
      placeholder.classList.remove('opened');
    }

    parts.forEach(function( item ) {
      item.classList.remove('hovered');
    })
  }

  const [resetPopup, doResetPopup] = useState(0);
    
  function hidePopup() {
    let parts = Array.from( document.querySelectorAll('.show'));    
    let placeholder = document.getElementsByClassName('plate--clear')[0];
    placeholder.classList.remove('opened');
    toggleOpen( false );
    doResetPopup(prev => prev + 1);    
    parts.forEach(function( item ) {
      item.classList.remove('show');
    })
  }

  const popups = data.popups.map( item => {
    let steps = item.steps.length;
    return <PlatePopup myClick={ hidePopup } currentSlide={0}resetPopup={resetPopup} key={item.id} item={item} steps={ steps } />
  })

  let windowWidth = window.innerWidth;
  window.addEventListener("scroll", function() {
    if( windowWidth > 1200) {
      const distance = window.scrollY
      document.querySelectorAll(".plate--bg-x")[0].style.transform = `translateY(${distance *
        -.09}px)`  
      document.querySelectorAll(".plate--bg-x")[1].style.transform = `translateY(${distance *
        -.11}px)`  
      document.querySelectorAll(".plate--bg-x")[2].style.transform = `translateY(${distance *
        -.15}px)`  
      }
    })

  return(
    <section className="plate">
      <div className="plate--arrow">
        <span>{ data.arrow }</span>
        <img alt="Plate arrow" src="images/layout/arrow.svg"></img> 
      </div>                    
    <div className="container">
      <div className="plate--inner">       
          <div className="plate--wrapper"> 

              {/* <img className="hidden" src="images/placeholder.png" alt="" /> */}
              <div className="plate--placeholder">
                <img className="plate--bg" src="images/placeholder-p-0.png" alt="" />
                <img className="plate--bg-x" src="images/placeholder-p-1.png" alt="" />
                <img className="plate--bg-x" src="images/placeholder-p-2.png" alt="" />
                <img className="plate--bg-x" src="images/placeholder-p-3.png" alt="" />
                <img className="plate--clear" src="images/placeholderclear.png" alt="" />
              </div>

              <svg className="plate--overlay" viewBox="0 0 956 954">
                <path onMouseEnter={hoverPopupIn} onMouseLeave={hoverPopupOut} onTouchStart={openPopup} onClick={openPopup} className="part-triangle" id="part_1"  d="M791.6,174.9C710.9,84.7,593.6,28,463,28c-35.4,0-69.9,4.2-102.9,12.1L501,471L791.6,174.9z"/>
                <path onMouseEnter={hoverPopupIn} onMouseLeave={hoverPopupOut} onTouchStart={openPopup} onClick={openPopup} className="part-triangle" id="part_2"  d="M360.1,40.1C268.7,61.9,188.4,112.2,129,181l372,290L360.1,40.1z"/>
                <path onMouseEnter={hoverPopupIn} onMouseLeave={hoverPopupOut} onTouchStart={openPopup} onClick={openPopup} className="part-triangle" id="part_3"  d="M898.6,400c-13.5-85.7-51.6-163.2-107-225L501,471L898.6,400z"/>
                <path onMouseEnter={hoverPopupIn} onMouseLeave={hoverPopupOut} onTouchStart={openPopup} onClick={openPopup} className="part-triangle" id="part_4"  d="M129,181C62.3,258.3,22,358.9,22,469c0,58.3,11.3,113.9,31.8,164.8L501,471L129,181z"/>
                <path onMouseEnter={hoverPopupIn} onMouseLeave={hoverPopupOut} onTouchStart={openPopup} onClick={openPopup} className="part-triangle" id="part_5"  d="M904,469c0-23.5-1.8-46.5-5.4-69L501,471l272.6,311.1C854.1,702.2,904,591.4,904,469z"/>
                <path onMouseEnter={hoverPopupIn} onMouseLeave={hoverPopupOut} onTouchStart={openPopup} onClick={openPopup} className="part-triangle" id="part_6"  d="M53.8,633.8c25.7,63.8,65.9,120.2,116.5,165.1L501,471L53.8,633.8z"/>
                <path onMouseEnter={hoverPopupIn} onMouseLeave={hoverPopupOut} onTouchStart={openPopup} onClick={openPopup} className="part-triangle" id="part_7"  d="M604.5,886.8c64.3-21.8,121.9-58,169.1-104.7L501,471L604.5,886.8z"/>
                <path onMouseEnter={hoverPopupIn} onMouseLeave={hoverPopupOut} onTouchStart={openPopup} onClick={openPopup} className="part-triangle" id="part_8"  d="M352.1,895.9c35.4,9.2,72.6,14.1,110.9,14.1c49.5,0,97.1-8.1,141.5-23.2L501,471L352.1,895.9z"/>  
                <path onMouseEnter={hoverPopupIn} onMouseLeave={hoverPopupOut} onTouchStart={openPopup} onClick={openPopup} className="part-triangle" id="part_9"  d="M170.3,798.9c51.3,45.6,113.3,79.3,181.8,97.1L501,471.1L170.3,798.9z"/>
              </svg>

              { popups }

              { opened && <div onClick={hidePopup} className="plate--popup-overlay">
              </div> }

            </div>
        </div>
      </div>
    </section>
  )
}